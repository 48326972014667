import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ApiService, BaseFunctions } from '../../app.service';
import { Observable, of, forkJoin } from 'rxjs';
import { ContextService } from '../../context.service';
import { NgForm } from '@angular/forms';


@Component({
    selector: 'editdog',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './editdog.component.html',
    styleUrls: ['./editdog.component.css']
})
export class EditDogComponent implements OnInit {

    @Input() public lang: string;
    //@Input() public editdog: any; 

    public EditDog: any;
    @Input() set editdog(value: any) {
        this.EditDog = value;
    } get editdog(): any {
        return this.EditDog;
    }

    @Input() public idnazioneowner: number;
    @Input() public nomeowner: string;
    @Input() public cognomeowner: string;

    @Output() cansavesubject = new EventEmitter();
    @Output() greyhounddata = new EventEmitter();
    @Output() lagottodata = new EventEmitter();
    @Output() cirnecodata = new EventEmitter();

    @ViewChild('editdogForm', { static: true }) public editdogForm: NgForm;

    public nazioni$: Observable<any>;
    public gruppifci$: Observable<any>;
    public sesso$: Observable<any>;

    public razze: any[];
    public razzeFiltered: any[];
    public IsLevriero: boolean = false;
    public IsLagotto: boolean = false;
    public IsCirneco: boolean = false;
    public editLev: any;
    public editLag: any;
    public editCir: any;
    public risultatiExpoAppenaAttivati: boolean = false;
    public nuovoRegolamentoLevrieri: boolean = false;
    public user$: Observable<any>;
    public impersonatorIsAdmin = false;

    constructor(
        private contextService: ContextService,
        private apiService: ApiService,
        private base: BaseFunctions
    ) { }

    public async ngOnInit() {
        this.user$ = this.contextService.currentUser$;
        this.sesso$ = this.apiService.get("Utils/GetGeneri", { pageLanguage: this.lang });
        this.nazioni$ = this.apiService.get("Utils/GetNazioni", null);
        this.gruppifci$ = this.apiService.get("Utils/GetGruppiFci", { pageLanguage: this.lang });
        const serverdata = forkJoin(
            this.apiService.get("Utils/GetRazze", { pageLanguage: this.lang }),
            this.apiService.get("Dogs/GetGreyhound", { idCane: this.editdog.Id ? this.editdog.Id : 0 }),
            this.apiService.get("Dogs/GetLagotto", { idCane: this.editdog.Id ? this.editdog.Id : 0 }),
            this.apiService.get("Dogs/GetCirneco", { idCane: this.editdog.Id ? this.editdog.Id : 0 }),
            this.apiService.get("Utils/GetRazzaMeticcio", { pageLanguage: this.lang }),
            this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "LevrieriDataNuovoRegolamento2022" })
        ).subscribe(([razze, lev, lag, cir, meticcio, datanuovoreglev]) => {
            this.razze = razze.dto;
            
            if (this.editdog.Gruppo) {
                this.razzeFiltered = this.razze.filter(r => r.Gruppo == this.editdog.Gruppo);
            }

            //per i meticci pulisco la lista e aggiungo solo questa, cos� non possono cmq cambiare razza
            if (this.editdog.TipoRegistrazione == 'MT') {
                this.razze = [];
                this.razze.push(meticcio.dto);
                this.razzeFiltered.push(meticcio.dto);
            }

            if (this.editdog.IdRazza) {
                var r = this.razze.find(u => u.Id == this.editdog.IdRazza);
                this.setFlagByRazza(r);
            }
            lev.dto.IdCane = this.editdog.Id;
            this.editLev = this.base.sanitizeDate(lev.dto);
            this.editLag = this.base.sanitizeDate(lag.dto);
            this.editCir = this.base.sanitizeDate(cir.dto);
            this.nuovoRegolamentoLevrieri = (new Date() >= this.base.getDateFormStringDDMMYYYY(datanuovoreglev.dto, "/"));
            this.contextService.currentUser$.subscribe(us => {
                if (us.impersonate) {
                    this.apiService.get("Users/GetGpRuoliByUserGuid", { userGuid: us.impersonate }).subscribe(roles => {

                        if (roles.dto.some(z => z.Nome == "Admin" && z.IsSelected)) {
                            this.impersonatorIsAdmin = true;
                        }
                    });
                }
                this.controllaForm();
            });
        });

        if (this.EditDog && this.EditDog.DataNascita) {
            this.EditDog.DataNascita = this.base.sanitizeSingleDate(this.EditDog.DataNascita);
        }

        this.editdogForm.valueChanges.subscribe(val => {
            this.controllaForm();
        });
    }

    private setFlagByRazza(rz: any) {
        this.IsLevriero = (rz && rz.ValidaXProveLevrieri);
        this.IsLagotto = (rz && rz.ValidaXProveLagotti);
        this.IsCirneco = (rz && rz.CodFci=='199');
    }

    private canSave = false;

    public controllaForm() {
        // Cirneco � anche considerato Levriero
        this.canSave = this.editdogForm.valid && (
            (this.editdog.TipoRegistrazione == 'R1') || //&& this.attachedAutorizzazioneRsr && this.attachedCertificatoRsr)
            (this.editdog.TipoRegistrazione == 'MT') ||
            // Straniero
            // Inserimento
            (this.attachedPedigree && !this.IsLevriero && !this.IsLagotto && !this.IsCirneco) ||
            (this.attachedPedigree && this.isRisultatoOkLagotto()) ||
            (this.attachedPedigree && this.IsLevriero && !this.IsCirneco && this.isRisultatoOkLevriero()) ||
            (this.attachedPedigree && this.IsCirneco && !this.IsLevriero && this.isRisultatoOkCirneco()) ||
            (this.attachedPedigree && this.IsLevriero && this.IsCirneco && this.isRisultatoOkLevriero() && this.isRisultatoOkCirneco()) ||
            // Edit
            ((this.editdog.Id > 0) && !this.IsLevriero && !this.IsLagotto && !this.IsCirneco) ||
            ((this.editdog.Id > 0) && this.isRisultatoOkLagotto()) ||
            ((this.editdog.Id > 0) && this.IsLevriero && !this.IsCirneco && this.isRisultatoOkLevriero()) ||
            ((this.editdog.Id > 0) && this.IsCirneco && !this.IsLevriero && this.isRisultatoOkCirneco()) ||
            ((this.editdog.Id > 0) && this.IsLevriero && this.IsCirneco && this.isRisultatoOkLevriero() && this.isRisultatoOkCirneco())
        );
        this.cansavesubject.emit(this.canSave);
        this.greyhounddata.emit(this.editLev);
        this.lagottodata.emit(this.editLag);
        this.cirnecodata.emit(this.editCir);
    }

    public isRisultatoOkLevriero() {
        if (this.IsLevriero) {
            //Se RisultatiInExpo � checkato, devo controllare che tutti i campi relativi siano compilati
            if (this.editLev.RisultatiInExpo) {
                if (this.base.isNullOrEmpty(this.editLev.Expo)
                    || this.base.isNullOrEmpty(this.editLev.QualificaExpo)
                    || this.base.isNullOrEmpty(this.editLev.DataExpo)
                    || (this.base.isNullOrEmpty(this.attachedRisultato) && (this.risultatiExpoAppenaAttivati))) {
                    return false;
                }
            }
            //Se LicenzaCacil � checkato, devo controllare che tutti i campi relativi siano compilati
            if (this.editdogForm.value.LicenzaCacil) {
                if (!this.impersonatorIsAdmin) {
                    if (this.base.isNullOrEmpty(this.attachedLicenzaCacil)
                        && this.base.isNullOrEmpty(this.editLev.NomeLicenzaCacilAllegato)
                    ) {
                        return false;
                    }
                }
            }
            //Se LicenzaCacil2023 � checkato, devo controllare che tutti i campi relativi siano compilati
            if (this.editdogForm.value.LicenzaCacil2023) {
                if (!this.impersonatorIsAdmin) {
                    if (this.base.isNullOrEmpty(this.attachedLicenzaCacil2023)
                        && this.base.isNullOrEmpty(this.editLev.NomeLicenzaCacil2023Allegato)
                    ) {
                        return false;
                    }
                }
            }
            return true;
        }
        return false;
    }

    public isRisultatoOkLagotto() {
        if (this.IsLagotto) {
            //Se RisultatiInExpo � checkato, devo controllare che tutti i campi relativi siano compilati
            if (this.editLag.RisultatiInProva) {
                if (this.base.isNullOrEmpty(this.editLag.Prova)
                    || this.base.isNullOrEmpty(this.editLag.CartellinoProva)
                    || this.base.isNullOrEmpty(this.editLag.DataProva)
                    || (this.base.isNullOrEmpty(this.attachedRisultato) && this.base.isNullOrEmpty(this.editLag.NomeRisultatoAllegato))
                ) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    public isRisultatoOkCirneco() {
        if (this.IsCirneco) {
            //Se CertificatoTipicitaInExpo � checkato, devo controllare che tutti i campi relativi siano compilati
            if (this.editCir.CertificatoTipicitaInExpo) {
                if (this.base.isNullOrEmpty(this.editCir.Expo)
                    || this.base.isNullOrEmpty(this.editCir.Qualifica)
                    || this.base.isNullOrEmpty(this.editCir.DataExpo)
                    || (!this.editCir.FlAuto && this.base.isNullOrEmpty(this.attachedRisultatoCir) && this.base.isNullOrEmpty(this.editCir.NomeRisultatoAllegatoCir))
                ) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    public attachedPedigree: any;
    public setPedigree(attachedPedigree) {
        this.attachedPedigree = attachedPedigree;
        this.editdog.AttachedPedigree = attachedPedigree.file;
        this.editdog.AttachedPedigreeName = attachedPedigree.name;
        this.controllaForm();
    }

    public attachedRisultato: any;
    public setRisultato(attachedRisultato) {
        this.attachedRisultato = attachedRisultato;
        this.editdog.AttachedRisultato = attachedRisultato.file;
        this.editdog.AttachedRisultatoName = attachedRisultato.name;
        this.controllaForm();
    }

    public attachedRisultatoCir: any;
    public setRisultatoCir(attachedRisultatoCir) {
        this.attachedRisultatoCir = attachedRisultatoCir;
        this.editdog.AttachedRisultatoCir = attachedRisultatoCir.file;
        this.editdog.AttachedRisultatoNameCir = attachedRisultatoCir.name;
        this.controllaForm();
    }

    public attachedLicenzaCacil: any;
    public setLicenzaCacil(attachedLicenzaCacil) {
        this.attachedLicenzaCacil = attachedLicenzaCacil;
        this.editdog.AttachedLicenzaCacil = attachedLicenzaCacil.file;
        this.editdog.AttachedLicenzaCacilName = attachedLicenzaCacil.name;
        this.controllaForm();
    }

    public attachedLicenzaCacil2023: any;
    public setLicenzaCacil2023(attachedLicenzaCacil2023) {
        this.attachedLicenzaCacil2023 = attachedLicenzaCacil2023;
        this.editdog.AttachedLicenzaCacil2023 = attachedLicenzaCacil2023.file;
        this.editdog.AttachedLicenzaCacil2023Name = attachedLicenzaCacil2023.name;
        this.controllaForm();
    }

    public downloadPedigree(pedigree) {
        this.apiService.get("Dogs/GetPedigree", { pedigree: pedigree }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public downloadRisultato(risultato) {
        this.apiService.get("Dogs/GetRisultato", { risultato: risultato }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public downloadAllegatoRsr(allegato) {
        this.apiService.get("Dogs/GetAllegatoRsr", { allegato: allegato }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public downloadLicenzaCacil(allegatoLicenza) {
        this.apiService.get("Dogs/GetLicenzaCacil", { licenzaCacil: allegatoLicenza }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public downloadLicenzaCacil2023(allegatoLicenza2023) {
        this.apiService.get("Dogs/GetLicenzaCacil2023", { licenzaCacil2023: allegatoLicenza2023 }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    changedLicenzaCacil(event: any) {
        if (!event) {
            this.attachedLicenzaCacil = undefined;
            this.editLev.NomeLicenzaCacilAllegato = undefined;
        }
    }

    changedLicenzaCacil2023(event: any) {
        if (!event) {
            this.attachedLicenzaCacil2023 = undefined;
            this.editLev.NomeLicenzaCacil2023Allegato = undefined;
        }
    }

    public groupChanged() {
        this.editdog.IdRazza = null;
        this.razzeFiltered = this.razze.filter(r => r.Gruppo == this.editdog.Gruppo);
    };

    public razzaChanged() {
        if (this.editdog.IdRazza) {
            var r = this.razze.find(u => u.Id == this.editdog.IdRazza);
            this.setFlagByRazza(r);
            this.editdog.RazzaDesc = r.RazzaDesc;
            return r.Gruppo;
        }
        return null;
    }

    public setRazzaMeticcio() {
        const serverdata = forkJoin(
            this.apiService.get("Utils/GetRazzaMeticcio", { pageLanguage: this.lang })
        ).subscribe(([meticcio]) => {
            //nel caso del meticcio pulisco per scrupolo tutte le razze e chi inserisco solo questa
            this.razze = [];
            this.razzeFiltered = [];
            this.razze.push(meticcio.dto);
            this.razzeFiltered.push(meticcio.dto);
            this.editdog.IdRazza = meticcio.dto.Id;
            this.editdog.RazzaDesc = meticcio.dto.RazzaDesc;
        });
    }

    public setPropStraniero() {
        if (this.editdog && !this.editdog.Id && this.idnazioneowner != 45 && this.editdog.TipoRegistrazione == 'S') {
            // In inserimento con soggetto e proprietario straniero metto default proprietario
            this.editdog.CognomePropStraniero = this.cognomeowner;
            this.editdog.NomePropStraniero = this.nomeowner;
        }
    }

}