import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ContextService } from '../../context.service';

@Component({
    selector: 'editragg',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './editragg.component.html',
    styleUrls: ['./editragg.component.css']
})
export class EditRaggComponent implements OnInit, OnDestroy {

    @Input() public myOwnedDogs: any[];
    @Input() public myConductedDogs: any[];
    @Input() public lang: string;
    @Input() public owner: any;
    @Input() public enciLoiUrl: any;
    @Input() public canDelete: boolean;
    @Input() public canSelect: boolean;
    @Input() public impersonamento: string;

    @Input() public enrolledDogsListC: any[] = [];
    @Input() public enrolledDogsListM: any[] = [];
    @Output() selectedRagg = new EventEmitter();
    @Output() hoRagg = new EventEmitter();

    @ViewChild('modalMakeCoppie', { static: false }) modalMakeCoppie: TemplateRef<any>;
    @ViewChild('modalMakeMute', { static: false }) modalMakeMute: TemplateRef<any>;
    @ViewChild('modalDeleteRagg', { static: false }) modalDeleteRagg: TemplateRef<any>;

    public modalMakeCoppieRef: BsModalRef | null;
    public modalMakeMuteRef: BsModalRef | null;
    public modalDeleteRaggRef: BsModalRef | null;

    private subscription: Subscription;

    public myRagg = [];
    public razzeCoppie = [];
    public razzeMute = [];
    public newRagg: {
        Id: number,
        Des: string,
        CodEnci: string,
        Soggetti: any[],
        Canettiere1: string,
        Canettiere2: string,
        Canettiere3: string,
        Tipo: string
    } = {
            Id: 0,
            Des: "",
            CodEnci: "",
            Soggetti: [],
            Canettiere1: "",
            Canettiere2: "",
            Canettiere3: "",
            Tipo: ""
        };
    public myFoundDogs: any[] = [];
    public codAltroProp = "";
    public trashingItem: any;
    public searching = false;
    public saving = false;

    public isTouchDevice = false;
    constructor(
        private translocoService: TranslocoService,
        private modalService: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService,
        private contextService: ContextService,
        private base: BaseFunctions
    ) {
        this.subscription = new Subscription();
        this.isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    }

    public selectRagg(r) {
        this.selectedRagg.emit(r);
    }

    public async ngOnInit() {

        this.subscription.add(
            forkJoin([
                this.apiService.get("GpRaggruppamenti/GetRazzeRagg", { tipo: "COPPIE", pageLanguage: this.lang }),
                this.apiService.get("GpRaggruppamenti/GetRazzeRagg", { tipo: "MUTE", pageLanguage: this.lang }),
                this.apiService.get("GpRaggruppamenti/GetGpRaggruppamenti", { userGuid: this.owner.IdUser, pageLanguage: this.lang })
            ]).subscribe(([coppie, mute, ragg]) => {
                this.razzeCoppie = coppie.dto;
                this.razzeMute = mute.dto;
                this.myRagg = ragg.dto;
                this.hoRagg.emit(this.myRagg.length > 0);
            })
        );

        this.subscription.add(
            this.contextService.raggPopup$.subscribe(t => {
                switch (t) {
                    case "C": this.modalMakeCoppieShow();
                        break;
                    case "M": this.modalMakeMuteShow();
                        break;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.contextService.openPop("");
    }

    private load() {
        this.apiService.get("GpRaggruppamenti/GetGpRaggruppamenti", { userGuid: this.owner.IdUser, pageLanguage: this.lang }).subscribe(ragg => {
            this.myRagg = ragg.dto;
        });
    }

    public getSexIcon(genere) {
        if (genere.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public modalMakeCoppieShow() {
        this.codAltroProp = "";
        this.newRagg = {
            Id: 0,
            Des: "COPPIA " + (this.myRagg.filter(t => t.Tipo == "C").length + 1).toString(),
            CodEnci: null,
            Soggetti: [],
            Canettiere1: this.owner.Cognome + " " + this.owner.Nome,
            Canettiere2: "",
            Canettiere3: "",
            Tipo: "C"
        };
        this.myFoundDogs = [];
        this.modalMakeCoppieRef = this.modalService.show(this.modalMakeCoppie, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
    }

    public modalMakeMuteShow() {
        this.codAltroProp = "";
        this.newRagg = {
            Id: 0,
            Des: "MUTA " + (this.myRagg.filter(t => t.Tipo == "M").length + 1).toString(),
            CodEnci: null,
            Soggetti: [],
            Canettiere1: this.owner.Cognome + " " + this.owner.Nome,
            Canettiere2: "",
            Canettiere3: "",
            Tipo: "M"
        };
        this.myFoundDogs = [];
        this.modalMakeMuteRef = this.modalService.show(this.modalMakeMute, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
    }

    public openModalDeleteRagg(r: any) {
        this.modalDeleteRaggRef = this.modalService.show(this.modalDeleteRagg, { ignoreBackdropClick: true, class: 'modal-delete-dog-ref' });
        this.trashingItem = r;
    }

    public deleteRaggConfirmed() {
        this.apiService.get("GpRaggruppamenti/DeleteRagg", { raggId: this.trashingItem.Id, impersonamento: this.impersonamento }).subscribe(ret => {
            if (!ret.ex) {
                this.myRagg = this.myRagg.filter(d => d.Id != this.trashingItem.Id);
                this.toastr.success(this.translocoService.translate('fe.label.ragg.deletesuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
            }
            this.load();
            this.modalDeleteRaggRef.hide();
        });
    }

    public changeRazza() {
        this.myOwnedDogs.forEach(d => { d.IsChecked = false; });
        this.myConductedDogs.forEach(d => { d.IsChecked = false; });
        this.newRagg.Soggetti = [];
        this.myFoundDogs = [];
    }

    public editRagg(r: any) {
        this.codAltroProp = "";
        this.myFoundDogs = [];
        this.myOwnedDogs.forEach(d => { d.IsChecked = false; });
        this.myConductedDogs.forEach(d => { d.IsChecked = false; });

        this.newRagg = Object.assign({}, r);

        this.newRagg.Soggetti.forEach(z => {
            let found = false;
            this.myOwnedDogs.forEach(d => {
                if (d.Id == z.CaniId) {
                    d.IsChecked = true;
                    found = true;
                }
            });
            this.myConductedDogs.forEach(d => {
                if (d.Id == z.CaniId) {
                    d.IsChecked = true;
                    found = true;
                }
            });
            if (!found) {
                z.Id = z.CaniId;
                z.IsChecked = true;
                this.myFoundDogs.push(z);
            }
        });

        if (r.Tipo == "C") {
            this.modalMakeCoppieRef = this.modalService.show(this.modalMakeCoppie, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
            this.newRagg["CodEnci"] = this.razzeCoppie.find(u => u.Id == this.newRagg["RazzaGenericaId"]).CodEnci;
        } else {
            this.modalMakeMuteRef = this.modalService.show(this.modalMakeMute, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
            this.newRagg["CodEnci"] = this.razzeMute.find(u => u.Id == this.newRagg["RazzaGenericaId"]).CodEnci;
        }
    }

    public addAltroProp(codEnci, lang) {
        this.searching = true;
        this.apiService.get("GpRaggruppamenti/Search", { searchString: this.codAltroProp, lang: lang }).subscribe(ret => {
            this.searching = false;
            if (!ret.ex) {
                if (ret.dto == null) {
                    this.toastr.warning(this.translocoService.translate('fe.warning.notfound'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                }
                else if (this.myFoundDogs.some(d => d.Chip == ret.dto.Chip)) {
                    this.toastr.warning(this.translocoService.translate('fe.warning.dogalreadyinragg'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                }
                else if (this.myOwnedDogs.some(d => d.Chip == ret.dto.Chip)) {
                    this.toastr.warning(this.translocoService.translate('fe.warning.owndog'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                }
                else if (this.myConductedDogs.some(d => d.Chip == ret.dto.Chip)) {
                    this.toastr.warning(this.translocoService.translate('fe.warning.conducteddog'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                }
                else if (ret.dto.CodEnci.indexOf(codEnci) == -1) {
                    this.toastr.warning(this.translocoService.translate('fe.warning.wrongbreed'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                }
                else {
                    ret.dto["IsChecked"] = true;
                    this.myFoundDogs.push(ret.dto);
                    this.codAltroProp = "";
                }
            }
        });
    }

    public soggettiSelezionati(da, a) {
        let selezionati = this.myOwnedDogs.filter(u => u.IsChecked).length + this.myConductedDogs.filter(u => u.IsChecked).length + this.myFoundDogs.filter(u => u.IsChecked).length
        if (selezionati >= da && selezionati <= a) {
            return false;
        }
        return true;
    }

    public saveRagg(lang) {
        this.saving = true;
        this.newRagg.Soggetti = [];
        if (this.newRagg.Tipo == "C") {
            this.newRagg["RazzaGenericaId"] = this.razzeCoppie.find(u => u.CodEnci == this.newRagg.CodEnci).Id;
        } else {
            this.newRagg["RazzaGenericaId"] = this.razzeMute.find(u => u.CodEnci == this.newRagg.CodEnci).Id;
        }


        for (var i = 0; i < this.myOwnedDogs.length; i++) {
            let dog = this.myOwnedDogs[i];
            if (dog["IsChecked"]) {
                this.newRagg.Soggetti.push({ "CaniId": dog["Id"] });
            }
        }

        for (var i = 0; i < this.myConductedDogs.length; i++) {
            let dog = this.myConductedDogs[i];
            if (dog["IsChecked"]) {
                this.newRagg.Soggetti.push({ "CaniId": dog["Id"] });
            }
        }

        for (var i = 0; i < this.myFoundDogs.length; i++) {
            let dog = this.myFoundDogs[i];
            if (dog["IsChecked"]) {
                this.newRagg.Soggetti.push({ "CaniId": dog["Id"] });
            }
        }

        this.apiService.post("GpRaggruppamenti/Save", { Ragg: this.newRagg, Lang: lang }).subscribe(ret => {
            this.saving = false;
            if (!ret.ex) {
                this.toastr.success(this.translocoService.translate('fe.label.raggsaved'), this.translocoService.translate('fe.generic.operation.completed'));
                if (this.newRagg.Id == 0) {
                    this.myRagg.push(ret.dto);
                } else {
                    let index = this.myRagg.map(e => e.Id).indexOf(ret.dto.Id);
                    this.myRagg[index] = ret.dto;
                }
                if (ret.dto.Tipo == "C") {
                    this.modalMakeCoppieRef.hide();
                } else {
                    this.modalMakeMuteRef.hide();
                }
            }
        });
    }

    public loadRaggDogs(ragg, lang) {
        for (let i = 0; i < ragg.Soggetti.length; i++) {
            let sogg = ragg.Soggetti[i];
            this.apiService.get("Dogs/GetDog", { chip: sogg.Chip, lang: lang }).subscribe(ret => {
                if (!ret.ex) {
                    sogg.Cane = ret.dto;
                }
            });
        }
    }

    public allDogsEnrolled(ragg) {
        var ret = true;
        ragg.Soggetti.forEach(s => {
            if (ragg.Tipo == "C") {
                if (this.enrolledDogsListC.indexOf(s.CaniId) == -1) {
                    ret = false;
                }
            }

            if (ragg.Tipo == "M") {
                if (this.enrolledDogsListM.indexOf(s.CaniId) == -1) {
                    ret = false;
                }
            }
        })
        return ret;
    }
}