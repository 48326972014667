import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'razzacodencifilter',
    pure: false
})
export class RazzaCodEnciFilterPipe implements PipeTransform {
    transform(items: any[], filter: string): any {
        if (!items || !filter) {
            return items;
        }
        let ret = items;
        ret = ret.filter(o => {
            if (o.CodEnci.indexOf(filter) > -1) {
                return true;
            }
            return false;
        });
        return ret;
    }
}