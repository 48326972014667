import { Component, OnInit } from '@angular/core';
import { ContextService } from '../context.service';

@Component({
    selector: 'app-informations',
    templateUrl: './informations.component.html',
})
export class InformationsComponent implements OnInit {

  constructor(
    private contextService: ContextService,
    ) { }

    public async ngOnInit() {
       
    }
}
