import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
})
export class StatisticsComponent implements OnInit {

    constructor(
    ) { }

    public async ngOnInit() {

    }
}