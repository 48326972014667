import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, TemplateRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ContextService } from '../../context.service';

@Component({
    selector: 'requisitiitem',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './requisitiitem.component.html',
    styleUrls: ['./requisitiitem.component.css']
})
export class RequisitiItemComponent implements OnInit, OnDestroy {

    @Input() public lang: string;
    @Input() public requisito: any;
    @Input() public IsImpersonamento: boolean = false;

    @Output() requisitoChange = new EventEmitter();

    public reqExpanded = false;
    public modalInfoRef: BsModalRef | null;
    public attachedRequisito: string = null;
    public attachedRequisitoName: string = null;
    public saving = false;

    private subscription: Subscription;
    constructor(
        private translocoService: TranslocoService,
        private modalService: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService,
        private contextService: ContextService,
        private base: BaseFunctions
    ) {
        this.subscription = new Subscription();
    }

    public async ngOnInit() {
        this.attachedRequisito = null;
        this.attachedRequisitoName = null;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getSexIcon(genere) {
        if (genere.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public showModalInfo(t) {
        this.modalInfoRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg' });
    }

    public getRequisito(guidRequisito) {
        this.apiService.get("Dogs/GetRequisito", { guidRequisito: guidRequisito }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                //a.style = "display: none";
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public setAttachedRequisito(attachedRequisito) {
        this.attachedRequisito = attachedRequisito.file;
        this.attachedRequisitoName = attachedRequisito.name;
    }

    public save() {
        this.saving = true;
        this.subscription.add(
            this.apiService.post("Dogs/SaveRequisito", { Requisito: this.requisito, AttachedRequisito: this.attachedRequisito, AttachedRequisitoName: this.attachedRequisitoName, Lang: this.lang }).subscribe(ret => {
                if (!ret.ex) {
                    this.toastr.success(this.translocoService.translate('fe.label.requisito.saved'), this.translocoService.translate('fe.generic.operation.completed'));
                    this.requisito = ret.dto;
                    this.requisitoChange.emit(this.requisito);
                    this.saving = false;    
                }
            })
        );
    }

    public delete() {
        this.saving = true;
        this.subscription.add(
            this.apiService.post("Dogs/DeleteRequisito", this.requisito ).subscribe(ret => {
                if (!ret.ex) {
                    this.toastr.success(this.translocoService.translate('fe.label.requisito.deleted'), this.translocoService.translate('fe.generic.operation.completed'));
                    this.requisito = ret.dto;
                    this.requisitoChange.emit(this.requisito);
                    this.saving = false;
                }
            })
        );
    }
}