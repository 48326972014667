import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
    selector: 'inscriptionlistitem',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './inscriptionlistitem.component.html',
    styleUrls: ['./inscriptionlistitem.component.css']
})
export class InscriptionListItemComponent implements OnInit {

    @Input() public item: any;
    @Input() public enciLoiUrl: any;
    @Input() public lang: string;
    @Input() public owner: any;
    @Input() public levReg2022: any = "01/01/2022";
    @Input() public startSeconds: number = 0;
    @Input() public postiPrenotati: number = 0;
    @Input() public titleLabelLeft: string = "Posti prenotati";
    @Input() public daysLabel: string = "giorni";
    @Input() public hoursLabel: string = "ore";
    @Input() public minutesLabel: string = "min";
    @Input() public secondsLabel: string = "sec";
    @Input() public titleLabelRight: string = "alla scadenza della prenotazione";

    private dogEnrollments$: Observable<any>;
    public modalInfoRef: BsModalRef | null;
    public modalCaniRaggRef: BsModalRef | null;

    public raggeditable = false;
    public raggeditmode = false;
    public caneSel: any;
    public caniRagg: [];

    public nuovoRegolamentoLevrieri: boolean = false;

    constructor(
        private translocoService: TranslocoService,
        private modalService: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService,
        private base: BaseFunctions
    ) { }

    public getSexIcon(genere) {
        if (genere.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public ngOnInit() {
        this.nuovoRegolamentoLevrieri = (this.base.getDate(this.item.Event.DataDal) >= this.base.getDateFormStringDDMMYYYY(this.levReg2022, "/"));
    }

    public showModalInfo(t, dog) {
        this.caneSel = dog;

        this.dogEnrollments$ = this.apiService.post("Payments/SubtrialsDogDetail",
            { userId: this.owner.IdUser, dogId: dog.IdCane, chip: dog.Chip, eventId: this.item.Event.Id, pageLanguage: this.lang, cartId: this.item.CartId });
        this.modalInfoRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg' });
    }

    public showModalCaniRagg(t, cani, raggeditable) {
        this.raggeditable = raggeditable;
        this.raggeditmode = false;
        this.caniRagg = cani;
        this.modalCaniRaggRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg' });
    }

    public getIntegerPart(number) {
        return Math.floor(number);
    }

    public checkCoppieMuteOk(item) {
        if (item.SpCoppieMuteConIscrizioni.some(sp => !sp.CoppieMuteOk)) {
            return false;
        }
        return true;
    }

    public getRaggruppamenti(razza) {
        let ret = [];
        
        this.base.groupByArray(razza.Associazioni, "GpPagamentiRaggruppamentiId").forEach(ragg => {
            let idCaniList = ragg.values.map(u => u.IdCane);
            let temp = {
                Id: ragg.values[0].IdRagg,
                Tipo: ragg.values[0].Tipo,
                RaggDesc: ragg.values[0].RaggDesc,
                Cani: razza.Iscrizioni.filter(isc => idCaniList.indexOf(isc.IdCane) > -1),
                Costo: ragg.values[0].CostoRaggruppamentoIntero,
                Canettieri: ragg.values[0].Canettieri,
                Telefono: ragg.values[0].Telefono
            }
            ret.push(temp);
        });
        return ret;
    }

    public saveCanettiereTelefono(r) {
        this.apiService.post("Payments/SaveCanettiereTelefono", { Ragg: r}).subscribe(ret => {
            if (this.base.isNullOrEmpty(ret.ex)) {
                this.toastr.success(this.translocoService.translate('fe.label.savecanettieretelefono.success'), this.translocoService.translate('fe.generic.operation.completed'));
                this.raggeditmode = false;        
            } 
        });
        
    }
}