import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], filter: string): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        var ret = items.filter(o => {
            for (var key in o) {
                if (o.hasOwnProperty(key) && (typeof o[key] === 'string' || o[key] instanceof String)) {
                    if (o[key].toLowerCase().includes(filter.toLowerCase())) {
                        return true;
                    }
                }
            }
            return false;
        });
        return ret;
    }
}