import { Component, OnInit, Input } from '@angular/core';
import { ContextService } from '../context.service';
import { Observable } from 'rxjs';
import { BaseFunctions } from '../app.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
    public owner$: Observable<any>;
    @Input() public linkshow: string;
    @Input() public linkhub: string;

    constructor(
        private contextService: ContextService,
        private base: BaseFunctions,
        private router: Router
    ) { }

    public ngOnInit() {
        this.owner$ = this.contextService.currentOwner$;
    }
}
