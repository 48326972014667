import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ContextService } from '../../context.service';

@Component({
    selector: 'requisiti',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './requisiti.component.html',
    styleUrls: ['./requisiti.component.css']
})
export class RequisitiComponent implements OnInit, OnDestroy {

    @Input() public lang: string;
    @Input() public sp: any = null;
    @Input() public dog: any;

    @Output() public requisiti = new EventEmitter();

    public IsImpersonamento: boolean = false;
    requisitiOttenuti: any[] = [];
    loading = true;

    private subscription: Subscription;
    constructor(
        private translocoService: TranslocoService,
        private modalService: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService,
        private contextService: ContextService,
        private base: BaseFunctions
    ) {
        this.subscription = new Subscription();
    }

    public async ngOnInit() {
        //Carico i requisiti del soggetto in canna
        this.subscription.add(
            this.apiService.get("Dogs/GetRequisiti", { chip: this.dog.Chip, lang: this.lang }).subscribe(ret => {
                if (!ret.ex) {
                    this.requisitiOttenuti = ret.dto;
                    this.requisiti.emit(this.requisitiOttenuti.filter(u => u.Id > 0));
                    this.loading = false;
                }
            })
        );
        this.subscription.add(
            this.contextService.currentUser$.subscribe(us => {
                if (us.impersonate) {
                    this.apiService.get("Users/GetGpRuoliByUserGuid", { userGuid: us.impersonate }).subscribe(roles => {
                        if (roles.dto.some(z => z.Nome == "Admin" && z.IsSelected)) {
                            this.IsImpersonamento = true;
                        }
                    });
                }
            })
        );
    }

    public requisitoChanged(index, newItem) {
        this.requisitiOttenuti[index] = newItem;
        this.requisiti.emit(this.requisitiOttenuti.filter(u => u.Id > 0));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getSexIcon(genere) {
        if (genere.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public requisitoPertinente(r) {
        if (this.sp) {
            if (r.PrvSpConfigSbloccate && r.PrvSpConfigSbloccate.some(p => p.Id == this.sp.PrvSpConfigId)) {
                return true;
            }
            return false;
        }
        return true;
    }
}