import { Component, OnInit, OnDestroy } from '@angular/core';
import { OidcSecurityService, AuthorizationResult, AuthorizationState } from 'angular-auth-oidc-client';
import { ContextService } from './context.service';
import { setTheme } from 'ngx-bootstrap/utils';
import { distinctUntilChanged, tap, map, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    onAuthorizationResultSubscription: Subscription;
    isDoneLoading: boolean = false;

    constructor(private oidcSecurityService: OidcSecurityService, private contextService: ContextService, private router: Router) {
        if (this.oidcSecurityService.moduleSetup) {
            this.doCallbackLogicIfRequired();
        } else {
            this.oidcSecurityService.onModuleSetup.subscribe(() => {
                this.doCallbackLogicIfRequired();
            });
        }
        setTheme('bs4'); //ngx-bootstrap
    }

    ngOnInit() {
        this.subscription = this.oidcSecurityService.getIsAuthorized().pipe(distinctUntilChanged()).subscribe(authorized => {
            authorized ? this.contextService.initUserContext() : this.contextService.initGuestContext();
        });

        this.onAuthorizationResultSubscription = this.oidcSecurityService.onAuthorizationResult.pipe(
          tap((authorizationResult: AuthorizationResult) => {
            console.debug('Auth result received AuthorizationState:'
              + authorizationResult.authorizationState
              + ' validationResult:' + authorizationResult.validationResult);
          }),
          map((authorizationResult: AuthorizationResult) => authorizationResult.authorizationState),
          tap((authorizationState: AuthorizationState) => {
            console.log(authorizationState);
              switch (authorizationState) {
                case AuthorizationState.unauthorized: 
                  this.router.navigate(['/']);
                case AuthorizationState.forbidden:
                  this.router.navigate(['/']);
                default:
                  const postLoginUrl = sessionStorage.getItem("postLoginUrl");
                  if (postLoginUrl) {
                    sessionStorage.removeItem("postLoginUrl");
                    this.router.navigateByUrl(postLoginUrl)
                  } else {
                    //this.router.navigate(["/"]);
                  }
              }
          })
          ).subscribe();

    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.onAuthorizationResultSubscription.unsubscribe();
        this.contextService.dispose();
    }

    private doCallbackLogicIfRequired() {
        // Will do a callback, if the url has a code and state parameter.
        this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
    }
}
