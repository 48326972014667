import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ApiService, BaseFunctions } from '../app.service';
import { ContextService } from '../context.service';
import { Observable, pipe, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

declare var $;

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
})
export class ResultsComponent implements OnInit, OnDestroy, AfterViewInit {
    ngOnDestroy(): void {
        this.annoSubject$.unsubscribe();
    }

    public eventoDaAprire: any = null;
    public enciLoiUrl$: Observable<any>;
    public lang$: Observable<any>;
    public eventiDto$: Observable<any>;
    public gpcodiciesra$: Observable<any>;
    public years$: Observable<any>;
    public codProtSudd = [];

    public filterYear = new Date().getFullYear();
    public annoSubject$ = new BehaviorSubject<any>(this.filterYear);

    public filterCodEsRa: string = "";
    public filterCodProtSudd: string = "";


    constructor(
        private apiService: ApiService,
        private contextService: ContextService,
        private base: BaseFunctions,
        private route: ActivatedRoute
    ) { }

    ngAfterViewInit(): void {
        this.eventoDaAprire = null;
        localStorage["idEventoDaAprire"] = this.route.snapshot.params["idEvento"];
        if (localStorage["idEventoDaAprire"]) {
            if (Number(localStorage["idEventoDaAprire"])) {
                this.contextService.currentLang$.subscribe(lang => {
                    this.apiService.get("/Results/GetGpEventoConRisultati", { idEvento: localStorage["idEventoDaAprire"], pageLanguage: lang }).subscribe(ev => {
                        if (ev.dto.Risultati.length > 0)
                            this.eventoDaAprire = ev.dto.Risultati[0];
                        if (ev.dto.RisultatiInEvidenza.length > 0)
                            this.eventoDaAprire = ev.dto.RisultatiInEvidenza[0];
                    });
                })
            } else {
                localStorage.removeItem("idEventoDaAprire");
            }
        }
    }

    public async ngOnInit() {
        this.enciLoiUrl$ = this.apiService.get("Utils/GetPar", { par: "enciLoiUrl" });
        this.lang$ = this.contextService.currentLang$;
        this.years$ = this.apiService.get("/Results/GetListaAnniRisultati", null).pipe(map(z => z.dto));
        this.eventiDto$ = this.contextService.currentLang$.pipe(switchMap(lang => this.apiService.get("/Results/GetGpEventiConRisultati", { anno: this.filterYear, pageLanguage: lang })));
        this.gpcodiciesra$ = this.contextService.currentLang$.pipe(switchMap(lang => this.apiService.get("/Utils/GetAllGpCodiciEsRa", { pageLanguage: lang })), map(z => z.dto));
        this.eventiDto$ = combineLatest([this.lang$, this.annoSubject$.asObservable()]).pipe(switchMap(([lang, year]) => {
            return this.apiService.get("/Results/GetGpEventiConRisultati", { anno: year, pageLanguage: lang });
        }));
    }

    public getCodProtSudd(lang) {
        this.filterCodProtSudd = "";
        if (this.base.isNullOrEmpty(this.filterCodEsRa)) {
            this.codProtSudd = [];
        } else {
            this.apiService.get("/Utils/GetUsedGpCodiciProtSudd", { codEsRa: this.filterCodEsRa, pageLanguage: lang }).subscribe(ret => {
                this.codProtSudd = ret.dto.filter(z => !this.base.isNullOrEmpty(z.Description));
            });
        }
    }
}
