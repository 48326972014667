import { Component, AfterViewInit, ViewChild, TemplateRef } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements AfterViewInit {

    public modalWarningTimeRef: BsModalRef | null;
    @ViewChild('modalWarningTime', { static: false }) modalWarningTime: TemplateRef<any>;

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private modalService: BsModalService) { }

    ngAfterViewInit() {
        this.modalWarningTimeShow(this.modalWarningTime);
    }

    public closeModalWarningTime() {
        this.modalWarningTimeRef.hide();
    }

    public modalWarningTimeShow(t: TemplateRef<any>) {
        this.modalWarningTimeRef = this.modalService.show(t, { ignoreBackdropClick: true});
    }

}
