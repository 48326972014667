import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'columnfilter',
    pure: false
})
export class ColumnFilterPipe implements PipeTransform {
    transform(items: any[], column: string, filter: string): any {
        
        if (!items || !column || !filter) {
            return items;
        }
        
        return items.filter(o => {
            if (o[column] != null)
                return o[column].toString().split('|').some(m => m == filter);
            return true;
        });
    }
}