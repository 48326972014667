import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { EnrollmentsComponent } from './enrollments/enrollments.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { ResultsComponent } from './results/results.component';
import { InformationsComponent } from './informations/informations.component';
import { WarningsComponent } from './warnings/warnings.component';
import { ErrorComponent } from './error/error.component';
import { SubtrialsComponent } from './subtrials/subtrials.component';
import { ConfEmailOkComponent } from './confemailok/confemailok.component';
import { ConfEmailErrorComponent } from './confemailerror/confemailerror.component';
import { OwnerComponent } from './owner/owner.component';
import { MyDogsComponent } from './mydogs/mydogs.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { InscriptionsComponent } from './inscriptions/inscriptions.component';
import { PasswordOkComponent } from './passwordok/passwordok.component';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'prefix',
        component: LayoutComponent,
        children: [
            {
                path: 'utils/confemailok',
                component: ConfEmailOkComponent
            },
            {
                path: 'utils/confemailerror',
                component: ConfEmailErrorComponent
            },
            {
                path: 'enrollments',
                component: EnrollmentsComponent
            },
            {
                path: 'home',
                component: HomeComponent
            },
            {
                path: 'statistics',
                component: StatisticsComponent
            },
            {
                path: 'catalogs',
                component: CatalogsComponent
            },
            {
                path: 'results',
                component: ResultsComponent
            },
            {
                path: 'results/:idEvento',
                component: ResultsComponent
            },
            {
                path: 'informations',
                component: InformationsComponent
            },
            {
                path: 'warnings',
                component: WarningsComponent
            },
            {
                path: 'subtrials/:id/:isfrom/:nSingoli',
                component: SubtrialsComponent
            },
            {
                path: 'subtrials/:id',
                component: SubtrialsComponent
            },
            {
                path: 'owner',
                canActivate: [AuthGuard],
                component: OwnerComponent
            },
            {
                path: 'mydogs',
                component: MyDogsComponent
            },
            {
                path: 'inscriptions',
                component: InscriptionsComponent
            },
            {
                path: 'inscriptions/:mode/:detail',
                component: InscriptionsComponent
            },
            {
                path: 'passwordok',
                component: PasswordOkComponent
            },
            {
                path: '',
                component: EnrollmentsComponent,
            },
            {
                path: 'unauthorized',
                component: UnauthorizedComponent
            },
            {
                path: '**',
                component: ErrorComponent
            }
        ]
    }
];