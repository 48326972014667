import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private _modalInfoSubject: Subject<{ open: boolean, evento: any }> = new Subject();
    private _modalResultSubject: Subject<{ open: boolean, evento: any }> = new Subject();
    private _modalVetogeneSubject: Subject<{ open: boolean }> = new Subject();

    public modalInfo$: Observable<{ open: boolean, evento: any }> = this._modalInfoSubject.asObservable();
    public modalResult$: Observable<{ open: boolean, evento: any }> = this._modalResultSubject.asObservable();
    public modalVetogene$: Observable<{ open: boolean }> = this._modalVetogeneSubject.asObservable();

    constructor() {
    }

    openModalInfo(evento: any) {
        this._modalInfoSubject.next({ open: true, evento: evento });
    }

    closeModalInfo() {
        this._modalInfoSubject.next({ open: false, evento: null });
    }

    openModalResult(evento: any) {
        this._modalResultSubject.next({ open: true, evento: evento });
    }

    closeModalResult() {
        this._modalResultSubject.next({ open: false, evento: null });
    }

    openModalVetogene() {
        this._modalVetogeneSubject.next({ open: true });
    }

    closeModalVetogene() {
        this._modalVetogeneSubject.next({ open: false });
    }
}



