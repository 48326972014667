import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-passwordok',
    templateUrl: './passwordok.component.html'
})
export class PasswordOkComponent implements OnInit {

  constructor(
  ) { }

  public async ngOnInit() {

  }
}
