import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { ContextService } from '../../context.service';

@Component({
    selector: 'doglistitem',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './doglistitem.component.html',
    styleUrls: ['./doglistitem.component.css']
})
export class DogListItemComponent implements OnInit {

    private _dog: any;

    @Input()
    set dog(value: any) {
        this._dog = value;
        // Licenza Cacil 2023 appena cercata
        if (this.licenzaCacil2023Cercata) {
            this.editLev.LicenzaCacil2023 = this.exLicenzaCacil2023Result;
        }
        const serverdata = forkJoin(
            this.apiService.get("Dogs/GetGreyhound", { idCane: this.dog.Id }),
            this.apiService.get("Dogs/GetLagotto", { idCane: this.dog.Id }),
            this.apiService.get("Dogs/GetCirneco", { idCane: this.dog.Id }),
            this.apiService.get("Utils/GetRazza", { idRazza: this.dog.IdRazza })
        ).subscribe(([lev, lag, cir, razza]) => {
            this.setFlagByRazza(razza.dto);
            lev.dto.IdCane = this.dog.Id;
            lag.dto.IdCane = this.dog.Id;
            cir.dto.IdCane = this.dog.Id;
            // Licenza Cacil 2023 appena cercata
            if (this.licenzaCacil2023Cercata) {
                lev.dto.LicenzaCacil2023 = this.exLicenzaCacil2023Result;
            }
            this.editLev = this.base.sanitizeDate(lev.dto);
            this.editLag = this.base.sanitizeDate(lag.dto);
            this.editCir = this.base.sanitizeDate(cir.dto);
        });
    } get dog(): any {
        return this._dog;
    }

    @Input() public enciLoiUrl: any;
    @Input() public lang: string;
    @Input() public userisowner: boolean;
    @Input() public nuovoRegolamentoLevrieri: boolean = false;
    @Output() greyhounddata = new EventEmitter();
    @Output() lagottodata = new EventEmitter();
    @Output() cirnecodata = new EventEmitter();

    public modalInfoRef: BsModalRef | null;
    public modalExpoEsteraRef: BsModalRef | null;
    public modalProvaEsteraRef: BsModalRef | null;
    public modalWarningNotOwnerRef: BsModalRef | null;
    public modalCirnecoCTRef: BsModalRef | null;
    public modalLicenzaCacil2023Ref: BsModalRef | null;

    public originalDog: any;
    public originalLev: any;
    public originalLag: any;
    public originalCir: any;

    public IsLevriero: boolean = false;
    public IsLagotto: boolean = false;
    public IsCirneco: boolean = false;
    public editLev: any;
    public editLag: any;
    public editCir: any;

    public exAttachedRisultato = null;
    public exDataExpo = null;
    public exQualificaExpo = null;
    public exExpo = null;
    public exNuoviDatiExpoEstera = false;

    public exDataProva = null;
    public exCartellinoProva = null;
    public exProva = null;
    public exNuoviDatiProvaEstera = false;

    public exAttachedRisultatoCir = null;
    public exDataExpoCir = null;
    public exQualificaCir = null;
    public exExpoCir = null;
    public exNuoviDatiCir = false;

    public exLicenzaCacil2023Result = null;
    public exLicenzaCacil2023Requisito1 = null;
    public exLicenzaCacil2023Requisito2 = null;

    public user$: Observable<any>;

    public impersonatorIsAdmin = false;
    public isTouchDevice = false;

    constructor(
        private translocoService: TranslocoService,
        private modalService: BsModalService,
        private modalService2: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService,
        private contextService: ContextService,
        private base: BaseFunctions
    ) {
        this.isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    }

    private setFlagByRazza(rz: any) {
        this.IsLevriero = (rz && rz.ValidaXProveLevrieri);
        this.IsLagotto = (rz && rz.ValidaXProveLagotti);
        this.IsCirneco = (rz && rz.CodFci == '199');
    }

    public getSexIcon() {
        if (this.dog && this.dog.Sesso && this.dog.Sesso.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public showModalInfo(t) {
        this.modalInfoRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-dog-list-item-show-info' + (this.isTouchDevice ? '' : ' ngDraggable') });
        this.originalLev = Object.assign({}, this.editLev);
        this.originalLag = Object.assign({}, this.editLag);
        this.originalCir = Object.assign({}, this.editCir);
        this.originalDog = Object.assign({}, this.dog);
    }

    public showModalWarningNotOwner(t) {
        this.modalWarningNotOwnerRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-dog-list-item-show-info' + (this.isTouchDevice ? '' : ' ngDraggable') });
    }

    //subscription: Subscription;
    //ngOnDestroy(): void {
    //    this.subscription.unsubscribe();
    //}

    public async ngOnInit() {
        this.user$ = this.contextService.currentUser$;
        this.modalService.onHide.subscribe(() => {
            if (this.originalDog != null && this.originalDog.Id == this.dog.Id) {
                this.editLev = this.originalLev;
                this.editLag = this.originalLag;
                this.editCir = this.originalCir;
                this.dog = this.originalDog;
            }
        });

        //21/06/2021 richiesto che dati levrieri fossero modificabili solo da particolari utenti (admin) CAS-46224-L5M0K4
        this.contextService.currentUser$.subscribe(us => {
            if (us.impersonate) {
                this.apiService.get("Users/GetGpRuoliByUserGuid", { userGuid: us.impersonate }).subscribe(roles => {
                    if (roles.dto.some(z => z.Nome == "Admin" && z.IsSelected)) {
                        this.impersonatorIsAdmin = true;
                    }
                });
            }
        });
    }

    public updateLight() {
        this.originalDog = null;
        this.originalLev = null;
        this.originalLag = null;
        this.originalCir = null;
        let attachedRisultato = null;
        let attachedRisultatoName = false;
        let attachedRisultatoCir = null;
        let attachedRisultatoNameCir = false;

        if (this.exNuoviDatiExpoEstera) {
            this.exNuoviDatiExpoEstera = false;
            this.editLev.DataExpo = this.exDataExpo;
            this.editLev.QualificaExpo = this.exQualificaExpo;
            this.editLev.Expo = this.exExpo;
            attachedRisultato = this.exAttachedRisultato.file;
            attachedRisultatoName = this.exAttachedRisultato.name;
            this.editLev.RisultatiInExpo = true;
        }

        if (this.exNuoviDatiProvaEstera) {
            this.exNuoviDatiProvaEstera = false;
            this.editLag.DataProva = this.exDataProva;
            this.editLag.CartellinoProva = this.exCartellinoProva;
            this.editLag.Prova = this.exProva;
            attachedRisultato = this.exAttachedRisultato.file;
            attachedRisultatoName = this.exAttachedRisultato.name;
            this.editLag.RisultatiInProva = true;
        }

        if (this.exNuoviDatiCir) {
            this.exNuoviDatiCir = false;
            this.editCir.CertificatoTipicitaInExpo = true;
            this.editCir.Expo = this.exExpoCir;
            this.editCir.DataExpo = this.exDataExpoCir;
            this.editCir.Qualifica = this.exQualificaCir;
            this.editCir.NomeRisultatoAllegatoCir = this.exAttachedRisultatoCir.name;
            this.editCir.FlAuto = this.base.isNullOrEmpty(this.exAttachedRisultatoCir.name);
            attachedRisultatoCir = this.exAttachedRisultatoCir.file;
            attachedRisultatoNameCir = this.exAttachedRisultatoCir.name;
        }

        this.apiService.post("Dogs/UpdateLight", { Dog: this.dog, DatiLevriero: this.editLev, DatiLagotto: this.editLag, DatiCirneco: this.editCir, AttachedRisultato: attachedRisultato, AttachedRisultatoName: attachedRisultatoName, AttachedRisultatoCir: attachedRisultatoCir, AttachedRisultatoNameCir: attachedRisultatoNameCir, Lang: this.lang })
            .subscribe(ret => {
                this.dog.MisurazioniLevrieroCorrette = (this.editLev.EsitoMisurazione1 != null) && (this.editLev.EsitoMisurazione2 != null);
                this.toastr.success(this.translocoService.translate('fe.label.dog.savelightsuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
                if (ret.dto.DatiLagotto) {
                    this.editLag = this.base.sanitizeDate(ret.dto.DatiLagotto);
                    this.lagottodata.emit(this.editLag);
                }
                if (ret.dto.DatiLevriero) {
                    this.editLev = this.base.sanitizeDate(ret.dto.DatiLevriero);
                    this.greyhounddata.emit(this.editLev);
                }
                if (ret.dto.DatiCirneco) {
                    this.editCir = this.base.sanitizeDate(ret.dto.DatiCirneco);
                    this.cirnecodata.emit(this.editCir);
                }
                this.modalInfoRef.hide()
            });
    }

    public tryingGetExpo = false;
    public tryGetExpo(t) {
        this.tryingGetExpo = true;
        this.apiService.get("Dogs/TryGetExpo", { loi: this.dog.Loi, chip: this.dog.Chip }).subscribe(ret => {
            this.tryingGetExpo = false;
            if (ret.dto) {
                this.editLev.RisultatiInExpo = true;
                this.editLev.Expo = ret.dto.Expo;
                this.editLev.QualificaExpo = ret.dto.QualificaExpo;
                this.editLev.DataExpo = this.base.sanitizeSingleDate(ret.dto.DataExpo);
                this.toastr.success(this.translocoService.translate('fe.tryGetExpo.riuscito.body'), this.translocoService.translate('fe.generic.operation.completed'));
            } else {
                this.clearExpoEstera();
                this.modalExpoEsteraRef = this.modalService2.show(t, { ignoreBackdropClick: true, class: (this.isTouchDevice ? '' : 'ngDraggable') });
            }
        });
    }

    public tryingGetProva = false;
    public tryGetProva(t) {
        this.tryingGetProva = true;
        this.apiService.get("Dogs/TryGetProva", { loi: this.dog.Loi, chip: this.dog.Chip }).subscribe(ret => {
            this.tryingGetProva = false;
            if (ret.dto) {
                this.editLag.RisultatiInProva = true;
                this.editLag.Prova = ret.dto.Prova;
                this.editLag.CartellinoProva = ret.dto.CartellinoProva;
                this.editLag.DataProva = this.base.sanitizeSingleDate(ret.dto.DataProva);
                this.toastr.success(this.translocoService.translate('fe.tryGetProva.riuscito.body'), this.translocoService.translate('fe.generic.operation.completed'));
            } else {
                this.clearProvaEstera();
                this.modalProvaEsteraRef = this.modalService2.show(t, { ignoreBackdropClick: true, class: (this.isTouchDevice ? '' : 'ngDraggable') });
            }
        });
    }

    public tryingGetCirnecoCT = false;
    public tryGetCirnecoCT(t) {
        this.tryingGetCirnecoCT = true;
        this.apiService.get("Dogs/TryGetCirnecoCT", { loi: this.dog.Loi, chip: this.dog.Chip })
            .subscribe(ret => {
                this.tryingGetCirnecoCT = false;
                if (ret.dto) {
                    this.editCir.CertificatoTipicitaInExpo = true;
                    this.editCir.Expo = ret.dto.Expo;
                    this.editCir.Qualifica = ret.dto.Qualifica;
                    this.editCir.DataExpo = this.base.sanitizeSingleDate(ret.dto.DataExpo);
                    this.editCir.FlAuto = true;
                    this.toastr.success(this.translocoService.translate('fe.TryGetCirnecoCT.riuscito.body'), this.translocoService.translate('fe.generic.operation.completed'));
                } else {
                    this.clearCirnecoCT();
                    this.modalCirnecoCTRef = this.modalService2.show(t, { ignoreBackdropClick: true, class: (this.isTouchDevice ? '' : 'ngDraggable') });
                }
            });
    }

    public tryingGetLicenzaCacil2023 = false;
    public licenzaCacil2023Cercata = false;
    public tryGetLicenzaCacil2023(t) {
        this.tryingGetLicenzaCacil2023 = true;
        this.apiService.get("Dogs/TryGetLicenzaCacil2023", { loi: this.dog.Loi, chip: this.dog.Chip })
            .subscribe(ret => {
                this.tryingGetLicenzaCacil2023 = false;
                this.licenzaCacil2023Cercata = true;
                if (ret.dto) {
                    this.clearLicenzaCacil2023();
                    if (ret.dto.RequisitiSoddisfatti) {
                        this.editLev.LicenzaCacil2023 = true;
                        this.exLicenzaCacil2023Result = true;
                        this.exLicenzaCacil2023Requisito1 = ret.dto.RisultatoRequisito1;
                        this.exLicenzaCacil2023Requisito2 = ret.dto.RisultatoRequisito2;
                    } else {
                        this.editLev.LicenzaCacil2023 = false;
                        this.exLicenzaCacil2023Result = false;
                        this.exLicenzaCacil2023Requisito1 = null;
                        this.exLicenzaCacil2023Requisito2 = null;
                    }
                    this.toastr.success(this.translocoService.translate('fe.TryGetLicenzaCacil2023.verify.true'), this.translocoService.translate('fe.generic.operation.completed'));
                    this.modalLicenzaCacil2023Ref = this.modalService2.show(t, { ignoreBackdropClick: true, class: (this.isTouchDevice ? '' : 'ngDraggable') });
                }
            });
    }

    public getPedigree(pedigree) {
        this.apiService.get("Dogs/GetPedigree", { pedigree: pedigree }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                //a.style = "display: none";
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public getAllegatoRsr(allegato) {
        this.apiService.get("Dogs/GetAllegatoRsr", { allegato: allegato }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                //a.style = "display: none";
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public getRisultato(risultato) {
        this.apiService.get("Dogs/GetRisultato", { risultato: risultato }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                //a.style = "display: none";
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public downloadLicenzaCacil(allegatoLicenza) {
        this.apiService.get("Dogs/GetLicenzaCacil", { licenzaCacil: allegatoLicenza }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public downloadLicenzaCacil2023(allegatoLicenza2023) {
        this.apiService.get("Dogs/GetLicenzaCacil2023", { licenzaCacil2023: allegatoLicenza2023 }).subscribe(ret => {
            var byteCharacters = atob(ret.dto.Blob);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var blob = new Blob([new Uint8Array(byteNumbers)], { type: ret.dto.MimeType });
            if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                var a = document.createElement("a");
                a.download = ret.dto.FileName;
                a.href = window["webkitURL"].createObjectURL(blob);
                a;
                a.click();
                setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
            } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = ret.dto.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        });
    }

    public clearExpoEstera() {
        this.exAttachedRisultato = null;
        this.exDataExpo = null;
        this.exQualificaExpo = null;
        this.exExpo = null;
    }

    public clearProvaEstera() {
        this.exAttachedRisultato = null;
        this.exDataProva = null;
        this.exCartellinoProva = null;
        this.exProva = null;
    }

    public clearCirnecoCT() {
        this.exAttachedRisultatoCir = null;
        this.exDataExpoCir = null;
        this.exQualificaCir = null;
        this.exExpoCir = null;
    }

    public clearLicenzaCacil2023() {
        this.exLicenzaCacil2023Result = false;
        this.exLicenzaCacil2023Requisito1 = null;
        this.exLicenzaCacil2023Requisito2 = null;
    }

    public getLabelSprinter(dog, editLev) {
        if (this.nuovoRegolamentoLevrieri)
            return '';
        else
            return (dog.TagliaXProve == 'SPRINTER' || (this.base.isNullOrEmpty(dog.TagliaXProve) && editLev &&
                (editLev.EsitoMisurazione2 == 'SPRINTER' || (this.base.isNullOrEmpty(editLev.EsitoMisurazione2) && (editLev.EsitoMisurazione1 == 'SPRINTER'))))) ? 'Sprinter' : '';
    }

    public setRisultato(attachedRisultato) {
        this.exAttachedRisultato = attachedRisultato;
    }

    public setRisultatoCir(attachedRisultatoCir) {
        this.exAttachedRisultatoCir = attachedRisultatoCir;
    }

    public confirmExpoEstera() {
        this.exNuoviDatiExpoEstera = true;
        this.modalExpoEsteraRef.hide();
        this.toastr.warning(this.translocoService.translate('fe.label.doglistitem.expoestera.warning'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
    }

    public confirmProvaEstera() {
        this.exNuoviDatiProvaEstera = true;
        this.modalProvaEsteraRef.hide();
        this.toastr.warning(this.translocoService.translate('fe.label.doglistitem.provaestera.warning'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
    }

    public confirmCirnecoCT() {
        this.exNuoviDatiCir = true;
        this.modalCirnecoCTRef.hide();
        this.toastr.warning(this.translocoService.translate('fe.label.doglistitem.provaestera.warning'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
    }

}