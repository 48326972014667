import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warnings',
  templateUrl: './warnings.component.html'
})
export class WarningsComponent implements OnInit {

  constructor(
  ) { }

  public async ngOnInit() {

  }
}
