import { Component, OnInit } from '@angular/core';
import { ApiService, BaseFunctions } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-catalogs',
    templateUrl: './catalogs.component.html',
})
export class CatalogsComponent implements OnInit {

    public user: any;
    public owner: any;
    public roles: string;
    public lang: string;
    public T: {};

    constructor(
        private apiService: ApiService,
        private toastr: ToastrService,
        private base: BaseFunctions,
        private route: ActivatedRoute
    ) { }

    public async ngOnInit() {
        
    }
}

