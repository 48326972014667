import { Component, OnInit } from '@angular/core';
import { ContextService } from '../context.service';
import { ApiService } from '../app.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

    public eventiDto$: Observable<any>;
    public lang$: Observable<any>;

    constructor(
        private contextService: ContextService,
        private apiService: ApiService) {
    }

    public async ngOnInit() {
        this.lang$ = this.contextService.currentLang$;
        this.eventiDto$ = this.contextService.currentLang$.pipe(switchMap(lang => this.apiService.get("/Events/GetAllGpEventiDto", { pageLanguage: lang, mode: "LIGHT" })));
    }
}

