import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../app.modalservice';

@Component({
    selector: 'eventdetailsresult',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './eventdetailsresult.component.html',
    styleUrls: ['./eventdetailsresult.component.css']
})
export class EventDetailsResultComponent implements OnInit {

    @Input() public evento: any;
    @Input() public lang: any;
    @Input() public openPop: boolean = false;
    public modalInfoRef: BsModalRef | null;
    
    constructor(
        private translocoService: TranslocoService,
        private apiService: ApiService,
        private toastr: ToastrService,
        private base: BaseFunctions,
        private modalService: ModalService
    ) { }

    public ngOnInit() {
        if (this.openPop) {
            this.modalService.openModalResult(this.evento);
            localStorage.removeItem("idEventoDaAprire");
        }
    }

    public showModalResult(evento: any) {
        this.modalService.openModalResult(evento);
    }

    public getSexIcon(sesso) {
        if (sesso && sesso.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public download(attach) {
        var byteCharacters = atob(attach.ByteArray);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        var blob = new Blob([new Uint8Array(byteNumbers)], { type: attach.MimeType });
        if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
            var a = document.createElement("a");
            a.download = attach.FileName;
            a.href = window["webkitURL"].createObjectURL(blob);
            a;
            a.click();
            setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
        } else {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            //a.style = "display: none";
            a.href = url;
            a.download = attach.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }
}