import { Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { ContextService } from '../context.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { withLatestFrom, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'az-inscriptions',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './inscriptions.component.html',
    styleUrls: ['./inscriptions.component.css']
})
export class InscriptionsComponent implements OnInit {

    @ViewChild('modalDownloadReceipt', { static: false }) modalDownloadReceipt: TemplateRef<any>;
    @ViewChild('modalMakeCoppie', { static: false }) modalMakeCoppie: TemplateRef<any>;
    @ViewChild('modalMakeMute', { static: false }) modalMakeMute: TemplateRef<any>;

    public lang$: Observable<any>;
    public enciLoiUrl$: Observable<any>;
    public owner$: Observable<any>;

    public inscriptions: any;
    public searchArchive: any;
    public activeTab = "notpaid";

    public modalDeleteInscriptionRef: BsModalRef | null;
    public modalRemoveFromGwRef: BsModalRef | null;
    public modalRemoveFromGwAndDeleteRef: BsModalRef | null;
    public modalConfirmInscriptionRef: BsModalRef | null;
    public modalConfirmInscriptionLemonwayRef: BsModalRef | null;
    public modalDownloadReceiptRef: BsModalRef | null;
    public modalEditCoppieMuteRef: BsModalRef | null;
    public modalMakeCoppieRef: BsModalRef | null;
    public modalMakeMuteRef: BsModalRef | null;
    public modalControlsDogsRef: BsModalRef | null;
    public modalSpiegaCoppieMuteRef: BsModalRef | null;

    //Parametri opzionali per aprire i messaggi di risultato pagamento
    public mode: string = '';
    public detail: string = '';

    //Parametri dei popup
    public confirmingInscription: any;
    public confirmingInscriptionCartId: number;
    public eventLabel: string;
    public receiptOwner: number;
    public itemPerCoppieMute: any;
    public currentSubtrial: any;
    public controlsRet: any;

    public cbConsensi: any;
    public cbriserva = false;

    public levReg2022: string = "01/01/2022";

    public soggettiCoppieMuteNonCompleti = 0;

    public alreadyExpanded = false;
    public ancheCoppieMute = false;

    public isTouchDevice = false;

    constructor(
        private translocoService: TranslocoService,
        private contextService: ContextService,
        private oidcSecurityService: OidcSecurityService,
        private modalService: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService,
        private base: BaseFunctions,
        private route: ActivatedRoute
    ) {
        this.isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    }

    public group(associazioni) {
        return this.base.groupByArray(associazioni, "GpPagamentiRaggruppamentiId");
    }

    public async ngOnInit() {
        this.lang$ = this.contextService.currentLang$;
        this.owner$ = this.contextService.currentOwner$;
        this.enciLoiUrl$ = this.apiService.get("Utils/GetPar", { par: "enciLoiUrl" });
        this.loadInscriptions();

        this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "LevrieriDataNuovoRegolamento2022" }).subscribe(ret => {
            this.levReg2022 = ret.dto;
        });
    }

    public async ngAfterViewInit() {
        this.mode = this.route.snapshot.params["mode"];
        this.detail = this.route.snapshot.params["detail"];

        if (this.mode == 'A') {
            this.toastr.warning(this.translocoService.translate('fe.label.lemonwayabortbyuser'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
        }
        if (this.mode == 'E') {
            if (this.detail == "0") {
                this.toastr.warning(this.translocoService.translate('fe.label.lemonwayerror') + ": " + this.translocoService.translate('fe.label.lemonwayerror.refused'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
            } else {
                this.apiService.get("Utils/GetGpLogLemonwayError", { logId: this.detail }).subscribe(ret => {
                    if (!ret.ex) {
                        this.toastr.warning(this.translocoService.translate('fe.label.lemonwayerror') + ": " + ret.dto.Error, this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                    }
                });
            }
        }
        if (this.mode == 'O') {
            //Devo andare sul server per prendere i dati ed assicurarmi che la ricevuta esista e l'utente possa scaricarla.
            //Dato che la ricevuta potrebbe non essere ancora pronta, tenter� una volta al secondo per 30 secondi prima di dire che la ricevuta non esiste
            this.modalDownloadReceiptRef = this.modalService.show(this.modalDownloadReceipt, { ignoreBackdropClick: true, class: 'modal-download-receipt-ref' });
            this.recursiveGetCartData(this.detail, 30);
        }
    }

    public recursiveGetCartData(cartId, tryAttempt) {
        if (tryAttempt == 0) {
            this.toastr.warning(this.translocoService.translate('fe.inscriptions.errorreceipt'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
        } else {
            this.apiService.get("Payments/GetCartData", { cartId: cartId }).subscribe(ret => {
                if (this.base.isNullOrEmpty(ret.ex) && ret.dto.Carrello != null) {
                    this.eventLabel = ret.dto.Evento.Localita + " (" + ret.dto.Evento.Provincia + ")";
                    this.confirmingInscriptionCartId = parseInt(cartId);
                    this.receiptOwner = ret.dto.Carrello.IdProprietarioPagante;
                } else {
                    setTimeout(() => this.recursiveGetCartData(cartId, tryAttempt - 1), 1000);
                }
            });
        }
    }

    public getSexIcon(genere) {
        if (genere.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public confirmInscriptionDisable() {
        return this.paying
            || !this.consensiSpuntati(this.cbConsensi)
            || (!this.cbriserva && this.confirmingInscription.AlmenoUnSoggettoInRiserva);
    }

    public consensiSpuntati(consensi) {
        return !this.cbConsensi.some(c => !c.FlChecked);
    }

    public checkCoppieMuteOk(item, owner) {
        let res = true;
        if (item.SpCoppieMuteConIscrizioni.some(sp => !sp.CoppieMuteOk)) {
            res = false;
        }
        this.soggettiCoppieMuteNonCompleti = 0;
        if (!res) {
            item.SpCoppieMuteConIscrizioni.forEach(s => {
                s.CoppieMuteRazza.forEach(cm => {
                    this.soggettiCoppieMuteNonCompleti += cm.Iscrizioni.filter(s => s.IdUser == owner.IdUser && s.IsCollegatoUser).length;
                });
            });
        }
        return res;
    }

    public giaInCoppiaMuta(iscrizione, associazioni) {
        return associazioni.some(a => a.IdCane == iscrizione.IdCane);
    };

    public nessunaCoppiaSelezionata(iscrizioni, associazioni) {
        let caniAssociati = associazioni.map(t => t.IdCane);
        let liberi = iscrizioni.filter(u => caniAssociati.indexOf(u.IdCane) == -1 && u.IsCollegatoUser);
        if (liberi.filter(s => s.IsSelected).length == 2) {
            return false;
        }
        return true;
    }

    public nessunaMutaSelezionata(iscrizioni, associazioni, MuteControllaMinMax, MuteMinSoggetti, MuteMaxSoggetti) {
        let caniAssociati = associazioni.map(t => t.IdCane);
        let liberi = iscrizioni.filter(u => caniAssociati.indexOf(u.IdCane) == -1 && u.IsCollegatoUser);
        let len = liberi.filter(s => s.IsSelected).length;
        // Controllo il numero di soggetti se previsto dalla sottoprova
        if (MuteControllaMinMax == 0 || (MuteControllaMinMax == 1 && len >= MuteMinSoggetti && len <= MuteMaxSoggetti)) {
            return false;
        }
        return true;
    }

    public savingCoppia = false;
    public creaCoppia(iscrizioni, associazioni, owner, idRazzaGenerica, lang, canettiere1, canettiere2, canettiere3) {

        //GF GENNAIO 2021: controllo che almeno un soggetto sia di proriet� dell'utente che st� creando il ragg
        let almenoUnSoggettoMio = false;
        let caniGiaAssociati = associazioni.map(t => t.IdCane);
        let liberi = iscrizioni.filter(u => caniGiaAssociati.indexOf(u.IdCane) == -1 && u.IsCollegatoUser);
        liberi.forEach(s => {
            if (s.IsSelected && s.IdUser == owner.IdUser) {
                almenoUnSoggettoMio = true;
            }
        });

        // TODO: se i soggetti sono di soci e non soci che prezzo prendiamo???????
        let primoCane = liberi.filter(u => u.IdUser == owner.IdUser)[0];
        let nuoveAssociazioni = [];

        //Vado sul server a farmi dare un nuovo ID raggruppamento
        if (almenoUnSoggettoMio) {
            this.savingCoppia = true;
            this.apiService.get("Payments/CreaCoppiaMutaVuota", { gpSottoProveId: primoCane.GpSottoProveId, tipo: 'C' }).subscribe(coppia => {

                if (this.base.isNullOrEmpty(coppia.ex)) {

                    liberi.forEach(s => {
                        if (s.IsSelected) {
                            nuoveAssociazioni.push(
                                {
                                    IdCane: s.IdCane,
                                    GpCoppieMuteId: coppia.dto.Id,
                                    Canettieri: (canettiere1 ? canettiere1 : '') + (canettiere2 ? ', ' + canettiere2 : '') + (canettiere3 ? ', ' + canettiere3 : ''),
                                    GpSottoProveId: s.GpSottoProveId,
                                    IdRazzaGenerica: idRazzaGenerica,
                                    Sesso: s.Sesso,
                                    Nome: s.Nome,
                                    Chip: s.Chip,
                                    Tipo: 'C',
                                    Loi: s.Loi,
                                    CostoRaggruppamentoIntero: primoCane.PrezzoSingolo
                                });
                        }
                    });

                    this.apiService.post("Payments/SaveAssCoppiaMuta", { associazioni: nuoveAssociazioni, PageLanguage: lang }).subscribe(ret => {
                        if (this.base.isNullOrEmpty(ret.ex)) {
                            let temp = this.currentSubtrial.CoppieMuteRazza.find(t => t.IdRazzaGenerica == idRazzaGenerica);
                            this.currentSubtrial.CoppieMuteRazza.find(t => t.IdRazzaGenerica == idRazzaGenerica).Associazioni = temp.Associazioni.concat(nuoveAssociazioni);
                            this.currentSubtrial.CoppieMuteRazza.forEach(cmr => {
                                cmr.AssociazioniGroup = this.group(cmr.Associazioni);
                            });
                        }
                        this.savingCoppia = false;
                        //this.canettieri = null;   //VS giu 2020 chiesto di non pulire il campo
                        this.refreshCoppieMuteOk(nuoveAssociazioni[0].CostoRaggruppamentoIntero, owner.IdUser);
                    });
                }
            });
        } else {
            this.toastr.warning(this.translocoService.translate('fe.label.atleastoneowneddog'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
        }
    }

    public codAltroProp = "";
    public addAltroProp(list) {
        let trovato = false;
        list.forEach(d => {
            if (d.Loi.toLowerCase() == this.codAltroProp.toLowerCase() || d.Chip == this.codAltroProp) {
                d.IsSelected = true;
                d.IsCollegatoUser = true;
                trovato = true;
                this.codAltroProp = '';
            }
        });
        if (!trovato) {
            this.toastr.warning(this.translocoService.translate('fe.label.inscriptions.addtoragg.notfound'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
        }
    }

    public removeAssCoppiaMuta(idAss, costoDaSottrarre, idRazzaGenerica, owner) {

        this.apiService.get("Payments/RemoveAssCoppiaMuta", { gpCoppieMuteId: idAss }).subscribe(ret => {
            if (this.base.isNullOrEmpty(ret.ex)) {
                let temp = this.currentSubtrial.CoppieMuteRazza.find(t => t.IdRazzaGenerica == idRazzaGenerica).Associazioni;
                this.currentSubtrial.CoppieMuteRazza.find(t => t.IdRazzaGenerica == idRazzaGenerica).Associazioni = temp.filter(x => x.GpCoppieMuteId != idAss);
                this.currentSubtrial.CoppieMuteRazza.forEach(cmr => {
                    cmr.AssociazioniGroup = this.group(cmr.Associazioni);
                });
                this.refreshCoppieMuteOk(-costoDaSottrarre, owner.IdUser);
            }
        });
    }

    public savingMuta = false;
    public creaMuta(iscrizioni, associazioni, owner, idRazzaGenerica, lang, canettiere1, canettiere2, canettiere3) {//Per i brevetti di muta idRazzaGenerica = -1

        let caniGiaAssociati = associazioni.map(t => t.IdCane);
        let liberi = iscrizioni.filter(u => caniGiaAssociati.indexOf(u.IdCane) == -1 && u.IsCollegatoUser);

        //GF GENNAIO 2021: controllo che almeno un soggetto sia di proriet� dell'utente che st� creando il ragg
        let almenoUnSoggettoMio = false;
        liberi.forEach(s => {
            if (s.IsSelected && s.IdUser == owner.IdUser) {
                almenoUnSoggettoMio = true;
            }
        });

        // TODO: se i soggetti sono di soci e non soci che prezzo prendiamo???????
        let primoCane = liberi.filter(u => u.IdUser == owner.IdUser)[0];
        let nuoveAssociazioni = [];

        //Vado sul server a farmi dare un nuovo ID raggruppamento
        if (almenoUnSoggettoMio) {
            this.savingMuta = true;
            this.apiService.get("Payments/CreaCoppiaMutaVuota", { gpSottoProveId: primoCane.GpSottoProveId, tipo: 'M' }).subscribe(muta => {

                if (this.base.isNullOrEmpty(muta.ex)) {

                    liberi.forEach(s => {
                        if (s.IsSelected) {
                            nuoveAssociazioni.push(
                                {
                                    IdCane: s.IdCane,
                                    GpCoppieMuteId: muta.dto.Id,
                                    Canettieri: (canettiere1 ? canettiere1 : '') + (canettiere2 ? ', ' + canettiere2 : '') + (canettiere3 ? ', ' + canettiere3 : ''),
                                    GpSottoProveId: s.GpSottoProveId,
                                    IdRazzaGenerica: idRazzaGenerica,
                                    Sesso: s.Sesso,
                                    Nome: s.Nome,
                                    Chip: s.Chip,
                                    Tipo: 'M',
                                    Loi: s.Loi,
                                    CostoRaggruppamentoIntero: primoCane.PrezzoSingolo
                                });
                        }
                    });

                    this.apiService.post("Payments/SaveAssCoppiaMuta", { associazioni: nuoveAssociazioni, PageLanguage: lang }).subscribe(ret => {
                        if (this.base.isNullOrEmpty(ret.ex)) {
                            let temp = this.currentSubtrial.CoppieMuteRazza.find(t => t.IdRazzaGenerica == idRazzaGenerica);
                            this.currentSubtrial.CoppieMuteRazza.find(t => t.IdRazzaGenerica == idRazzaGenerica).Associazioni = temp.Associazioni.concat(nuoveAssociazioni);
                            this.currentSubtrial.CoppieMuteRazza.forEach(cmr => {
                                cmr.AssociazioniGroup = this.group(cmr.Associazioni);
                            });
                        }
                        this.savingMuta = false;
                        //this.canettieri = null;   //VS giu 2020 chiesto di non pulire il campo
                        this.refreshCoppieMuteOk(nuoveAssociazioni[0].CostoRaggruppamentoIntero, owner.IdUser);
                    });
                }
            });
        } else {
            this.toastr.warning(this.translocoService.translate('fe.label.atleastoneowneddog'), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
        }
    }

    private loadInscriptions() {
        this.contextService.currentUser$.pipe(
            withLatestFrom(this.contextService.currentLang$),
            switchMap(([user, lang]) => {
                if (user) {
                    return forkJoin(this.apiService.get("Payments/GetInscriptions", { userGuid: user.sub, pageLanguage: lang }),
                        this.apiService.get("Users/GetPrenotazioniAttiveByUtente", { userGuid: user.sub }));
                }
                return of(null);
            })
        ).subscribe(([inscr, pren]) => {
            this.inscriptions = { Paid: [], ToGw: [] };
            this.inscriptions.NotPaid = inscr.dto.NotPaid;
            this.ancheCoppieMute = false;
            this.inscriptions.NotPaid.forEach(item => {
                if (item.SpCoppieMuteConIscrizioni.some(o => o.HoSoggettiIscrittiPerCoppieMute)) {
                    this.ancheCoppieMute = true;
                }
                let prenUtente = pren.dto.find(w => w.GpEventiId == item.Event.Id);
                this.setPrenotazione(item, prenUtente);

            });
            inscr.dto.Paid.forEach(item => {
                if (!this.inscriptions.Paid.some(o => o.gpEventiId == item.Event.Id)) {
                    this.inscriptions.Paid.push({ gpEventiId: item.Event.Id, eventItems: inscr.dto.Paid.filter(v => v.Event.Id == item.Event.Id) });
                }
            });

            inscr.dto.ToGw.forEach(item => {
                if (!this.inscriptions.ToGw.some(o => o.gpEventiId == item.Event.Id)) {
                    this.inscriptions.ToGw.push({ gpEventiId: item.Event.Id, eventItems: inscr.dto.ToGw.filter(v => v.Event.Id == item.Event.Id) });
                }
            });
        });
    }

    public setPrenotazione(inscrEvento, pren) {
        if (pren && pren.SecondsValidity > 0) {
            inscrEvento.Event.prenotazioneSecondsValidity = pren.SecondsValidity;
            inscrEvento.Event.postiPrenotatiUtente = pren.NumPostiPrenotati;
        } else {
            inscrEvento.Event.prenotazioneSecondsValidity = 0;
            inscrEvento.Event.postiPrenotatiUtente = 0;
        }
    }

    public getInscriptionsArchive(lang, from, to) {
        this.apiService.get("Payments/GetInscriptionsArchive", { pageLanguage: lang, from: from, to: to }).subscribe(ret => {
            this.searchArchive = [];
            ret.dto.forEach(item => {
                if (!this.searchArchive.some(o => o.gpEventiId == item.Event.Id)) {
                    this.searchArchive.push({ gpEventiId: item.Event.Id, eventItems: ret.dto.filter(v => v.Event.Id == item.Event.Id) });
                }
            });
        });
    }

    public downloadReceipt(cartId, lang, mode) {
        this.apiService.get("Payments/DownloadReceipt", { cartId: cartId, pageLanguage: lang, mode: mode }).subscribe(ret => {
            if (!ret.ex) {
                if (ret.dto.Warning) {
                    this.toastr.warning(this.translocoService.translate('fe.label.' + ret.dto.Warning), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                } else {
                    var byteCharacters = atob(ret.dto.Blob);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }

                    var blob = new Blob([new Uint8Array(byteNumbers)], { type: "application/pdf" });

                    if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
                        var a = document.createElement("a");
                        a.download = ret.dto.FileName;
                        a.href = window["webkitURL"].createObjectURL(blob);
                        a;
                        a.click();
                        setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
                    } else {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        //a.style = "display: none";
                        a.href = url;
                        a.download = ret.dto.FileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                }
            }
        });
    }

    public trashingInscription: any;
    public modalDeleteInscriptionShow(t: TemplateRef<any>, item: any) {
        this.modalDeleteInscriptionRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-delete-inscription-ref' + (this.isTouchDevice ? '' : ' ngDraggable') });
        this.trashingInscription = item;
    }

    public deleteInscriptionConfirmed() {
        this.apiService.get("Payments/DeleteInscription", { eventId: this.trashingInscription.Event.Id }).subscribe(ret => {
            if (!ret.ex) {
                this.inscriptions.NotPaid = this.inscriptions.NotPaid.filter(i => i.Event.Id != this.trashingInscription.Event.Id);
                this.toastr.success(this.translocoService.translate('fe.label.inscription.deletesuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
            }
            this.loadInscriptions();
            this.modalDeleteInscriptionRef.hide();
        });
    }

    public inGwPayment: any;
    public modalRemoveFromGwShow(t: TemplateRef<any>, item: any) {
        this.modalRemoveFromGwRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-delete-inscription-ref' + (this.isTouchDevice ? '' : ' ngDraggable') });
        this.inGwPayment = item;
    }

    public modalRemoveFromGwAndDeleteShow(t: TemplateRef<any>, item: any) {
        this.modalRemoveFromGwAndDeleteRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-delete-inscription-ref' + (this.isTouchDevice ? '' : ' ngDraggable') });
        this.inGwPayment = item;
    }

    public removeFromGwConfirmed() {
        this.apiService.get("Payments/RemoveFromGw", { cartId: this.inGwPayment.CartId }).subscribe(ret => {
            if (!ret.ex) {
                this.toastr.success(this.translocoService.translate('fe.label.removefromgw.successful'), this.translocoService.translate('fe.generic.operation.completed'));
                this.loadInscriptions();
                this.activeTab = "notpaid";
            }
            this.modalRemoveFromGwRef.hide();
        });
    }

    public removeFromGwAndDeleteConfirmed() {
        this.apiService.get("Payments/RemoveFromGwAndDelete", { cartId: this.inGwPayment.CartId }).subscribe(ret => {
            if (!ret.ex) {
                this.toastr.success(this.translocoService.translate('fe.label.removefromgwanddelete.successful'), this.translocoService.translate('fe.generic.operation.completed'));
                this.loadInscriptions();
                this.activeTab = "notpaid";
            }
            this.modalRemoveFromGwAndDeleteRef.hide();
        });
    }

    public refreshCoppieMuteOk(variazionePrezzo, idUser) {

        this.itemPerCoppieMute.Total = this.itemPerCoppieMute.Total + variazionePrezzo;
        this.itemPerCoppieMute.SpCoppieMuteConIscrizioni.forEach(subtrial => {
            subtrial.CoppieMuteOk = true;
            //Questa roba significa che, ragionando per razza, se ho almeno un cane iscritto che non � in nessuna associazione 
            // allora la sottoprova NON � OK per quanto riguarda coppie e mute
            for (let razza of subtrial.CoppieMuteRazza) {
                let idCaniInCoppieMute = razza.Associazioni.map(u => u.IdCane);
                // Per controllare se coppie/mute sono complete considero solo i cani iscritti dall'utente corrente
                // Questo perch� tra le iscrizioni vengono caricate anche tutte quelle degli altri proprietari
                let iscrizioniMie = razza.Iscrizioni.filter(w => w.IdUser == idUser);
                if (iscrizioniMie.some(isc => idCaniInCoppieMute.indexOf(isc.IdCane) == -1)) {
                    subtrial.CoppieMuteOk = false;
                    break;
                }
            }
        });
    }

    public checking = false;

    public modalConfirmInscriptionShow(t: TemplateRef<any>, tControls: TemplateRef<any>, item: any, lang) {
        this.checking = true;
        this.confirmingInscription = item;
        this.cbConsensi = item.Consensi;
        this.cbConsensi.forEach(cons => {
            cons.FlChecked = false;
        });
        this.cbriserva = false;
        this.controlsRet = [];
        this.apiService.get("Payments/ControllaCane", { eventId: this.confirmingInscription.Event.Id, requestedLang: lang }).subscribe(ret => {
            if (ret.ex) {
                this.checking = false;
                this.controlsRet = ret.dto;
                this.modalControlsDogsRef = this.modalService.show(tControls, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
            }
            else {
                this.checking = false;
                this.modalConfirmInscriptionRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
            }
        });
    }

    public modalConfirmInscriptionLemonwayShow(t: TemplateRef<any>, tControls: TemplateRef<any>, item: any, lang) {
        this.checking = true;
        this.confirmingInscription = item;
        this.cbConsensi = item.Consensi;
        this.cbConsensi.forEach(cons => {
            cons.FlChecked = false;
        });
        this.cbriserva = false;

        this.controlsRet = [];
        this.apiService.get("Payments/ControllaCane", { eventId: this.confirmingInscription.Event.Id, requestedLang: lang }).subscribe(ret => {
            if (ret.ex) {
                this.checking = false;
                this.controlsRet = ret.dto;
                this.modalControlsDogsRef = this.modalService.show(tControls, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
            }
            else {
                this.checking = false;
                this.modalConfirmInscriptionLemonwayRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
            }
        });
    }

    public modalEditCoppieMuteShow(t: TemplateRef<any>, item: any) {
        this.itemPerCoppieMute = item;
        // Se ho solo una sottoprova di tipo Coppie/Mute entro direttamente in gestione
        let countCoppieMute = 0;
        let subTrial = undefined;
        this.itemPerCoppieMute.SpCoppieMuteConIscrizioni.forEach(cmr => {
            if (cmr.HoSoggettiIscrittiPerCoppieMute) {
                countCoppieMute++;
                subTrial = cmr;
            }
        });
        if (countCoppieMute == 1) {
            if (subTrial.CodSubKey == 'COPPIE') {
                this.modalMakeCoppieShow(this.modalMakeCoppie, subTrial);
            } else {
                this.modalMakeMuteShow(this.modalMakeMute, subTrial);
            }
        } else {
            this.modalEditCoppieMuteRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
        }
    }

    public modalMakeCoppieShow(t: TemplateRef<any>, subtrial: any) {
        this.currentSubtrial = undefined;
        let canettiere1 = '';
        this.currentSubtrial = subtrial;
        this.currentSubtrial.CoppieMuteRazza.forEach(cmr => {
            cmr.AssociazioniGroup = this.group(cmr.Associazioni);
            // Se sono esattamente 2 soggetti li selezioniamo gi� per la coppia e valorizziamo il canettiere da quello scritto in iscrizione
            if (cmr.Iscrizioni.length == 2) {
                cmr.Iscrizioni.forEach(iscr => {
                    iscr.IsSelected = true;
                    canettiere1 = iscr.Conduttore;
                })
            }
            cmr.canettiere1 = canettiere1;
            cmr.canettiere2 = '';
            cmr.canettiere3 = '';
        });
        this.alreadyExpanded = (this.currentSubtrial.CoppieMuteRazza.length == 1);
        this.modalMakeCoppieRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
    }

    public modalSpiegaCoppieMuteShow(t: TemplateRef<any>) {
        this.modalSpiegaCoppieMuteRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
    }

    public modalMakeMuteShow(t: TemplateRef<any>, subtrial: any) {
        this.currentSubtrial = undefined;
        let canettiere1 = '';
        this.currentSubtrial = subtrial;
        this.currentSubtrial.CoppieMuteRazza.forEach(cmr => {
            cmr.AssociazioniGroup = this.group(cmr.Associazioni);
            // Se sono nel numero giusto per fare una muta li selezioniamo gi� e valorizziamo il canettiere da quello scritto in iscrizione
            if (cmr.Iscrizioni.length > 0 && cmr.Iscrizioni.length >= this.currentSubtrial.MuteMinSoggetti && cmr.Iscrizioni.length <= this.currentSubtrial.MuteMaxSoggetti) {
                cmr.Iscrizioni.forEach(iscr => {
                    iscr.IsSelected = true;
                    canettiere1 = iscr.Conduttore;
                })
            }
            cmr.canettiere1 = canettiere1;
            cmr.canettiere2 = '';
            cmr.canettiere3 = '';
        });
        this.alreadyExpanded = (this.currentSubtrial.CoppieMuteRazza.length == 1);
        this.modalMakeMuteRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
    }

    public paying = false;
    public confirmInscriptionConfirmed(mdr, lang, ownerId) {
        this.paying = true;
        this.apiService.get("Payments/ConfirmInscription", { eventId: this.confirmingInscription.Event.Id, requestedLang: lang }).subscribe(ret => {
            this.paying = false;
            if (!ret.ex) {
                if (ret.dto.ErrorCode) {
                    this.loadInscriptions();
                    this.toastr.warning(this.translocoService.translate('fe.inscription.error.' + ret.dto.ErrorCode), this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                }
                else {
                    this.confirmingInscriptionCartId = ret.dto.CartId;
                    this.eventLabel = this.confirmingInscription.Event.Localita + " (" + this.confirmingInscription.Event.Provincia + ")";
                    this.inscriptions.NotPaid = this.inscriptions.NotPaid.filter(i => i.Event.Id != this.confirmingInscription.Event.Id);
                    this.receiptOwner = ownerId;
                    this.modalDownloadReceiptRef = this.modalService.show(mdr, { ignoreBackdropClick: true, class: 'modal-download-receipt-ref' + (this.isTouchDevice ? '' : ' ngDraggable') });
                }
            }
            this.loadInscriptions();
            this.modalConfirmInscriptionRef.hide();
        });
    }

    public startLemonwayPayment(lang) {
        this.paying = true;
        this.apiService.get("Payments/StartLemonwayPayment", { eventId: this.confirmingInscription.Event.Id, requestedLang: lang }).subscribe(ret => {
            if (ret.ex) {
                this.paying = false;
                this.loadInscriptions();
            }
            else {
                window.location.href = ret.dto;
            }
        });
    }
}