import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'ldate',
  pure: false
})
export class LocaleDatePipe implements PipeTransform {
  private value: string | null;
  private lastDate: string | Date;
  private lastLang: string;

  constructor(private tranlationService: TranslocoService) {
  }

  transform(date: string | Date, format: string, timezone: string): string {
    const currentLang = this.tranlationService.getActiveLang();

    // if we ask another time for the same date & locale, return the last value
    if (date === this.lastDate && currentLang === this.lastLang) {
      return this.value;
    }

    this.value = new DatePipe(currentLang).transform(date, format, timezone, currentLang);
    this.lastDate = date;
    this.lastLang = currentLang;

    return this.value;
  }
}