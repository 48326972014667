import { Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild } from '@angular/core';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { ContextService } from '../context.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { withLatestFrom, map, mergeMap, take, switchMap } from 'rxjs/operators';
import { OnDestroy } from '../../../node_modules/@angular/core/core';

declare var $;

@Component({
    selector: 'az-mydogs',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './mydogs.component.html',
    styleUrls: ['./mydogs.component.css']
})
export class MyDogsComponent implements OnInit, OnDestroy {

    public modalDeleteDogRef: BsModalRef | null;
    public modalUnconnectDogRef: BsModalRef | null;
    public modalAddDogRef: BsModalRef | null;
    public modalEditDogRef: BsModalRef | null;
    public modalLimitazioniRsrRef: BsModalRef | null;
    public modalRequisitiRef: BsModalRef | null;

    public lang$: Observable<any>;
    public enciLoiUrl$: Observable<any>;
    public owner$: Observable<any>;
    public user$: Observable<any>;

    public cfpiOccupato = true;
    public datiEnciTributiOk = false;
    public mydogs: any;
    public originalDog: any;

    public myDogsOpen = true;
    public conductedOpen = false;
    public myRaggOpen = false;

    public nuovoRegolamentoLevrieri: boolean = false;
    private subscription: Subscription;

    public isTouchDevice = false;

    constructor(
        private translocoService: TranslocoService,
        private contextService: ContextService,
        private oidcSecurityService: OidcSecurityService,
        private modalService: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService,
        private base: BaseFunctions
    ) {
        this.subscription = new Subscription();
        this.isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public async ngOnInit() {
        this.lang$ = this.contextService.currentLang$;
        this.user$ = this.contextService.currentUser$;
        this.owner$ = this.contextService.currentOwner$;
        this.enciLoiUrl$ = this.apiService.get("Utils/GetPar", { par: "enciLoiUrl" });
        this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "LevrieriDataNuovoRegolamento2022" }).subscribe(ret => {
            this.nuovoRegolamentoLevrieri = (new Date() >= this.base.getDateFormStringDDMMYYYY(ret.dto, "/"));
            this.loadDogs();
        });
        this.subscription.add(
            this.modalService.onHide.subscribe(() => {
                if (this.originalDog != null) {
                    this.editdog = this.originalDog;
                }
            }));
    }

    private loadDogs() {
        this.subscription.add(
            this.contextService.currentUser$.pipe(
                withLatestFrom(this.contextService.currentLang$),
                switchMap(([user, lang]) => {
                    if (user) {
                        return forkJoin([
                            this.apiService.get("Users/GetMyDogs", { userGuid: user.sub, pageLanguage: lang, evId: 0 })
                        ]);
                    }
                })
            ).subscribe(([ret]) => {
                this.mydogs = ret.dto;
                this.mydogs.MyOwnedDogs = this.mydogs.MyOwnedDogs.sort((a, b) => ((a.Nome && b.Nome && a.Nome.toUpperCase() > b.Nome.toUpperCase()) ? 1 : -1));
                this.mydogs.MyConductedDogs = this.mydogs.MyConductedDogs.sort((a, b) => ((a.Nome > b.Nome) ? 1 : -1));
                this.conductedOpen = this.mydogs.MyConductedDogs.length > 0;
            }));
    }

    public trashingItem: any;

    public modalDeleteDogShow(t: TemplateRef<any>, c: any) {
        this.modalDeleteDogRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-delete-dog-ref' + (this.isTouchDevice ? '' : ' ngDraggable') });
        this.trashingItem = c;
        this.apiService.get("Dogs/GetValidoPerCoppieMute", { idRazza: this.trashingItem.IdRazza }).subscribe(ret => {
            if (ret.ex) {
                this.trashingItem.ValidoPerCoppieMute = false;
            } else {
                this.trashingItem.ValidoPerCoppieMute = ret.dto;
            }
        });
    }

    public modalUnconnectDogShow(t: TemplateRef<any>, c: any) {
        this.modalUnconnectDogRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-unconnect-dog-ref' + (this.isTouchDevice ? '' : ' ngDraggable') });
        this.trashingItem = c;
        this.apiService.get("Dogs/GetValidoPerCoppieMute", { idRazza: this.trashingItem.IdRazza }).subscribe(ret => {
            if (!ret.ex) {
                this.trashingItem.ValidoPerCoppieMute = ret.dto;
            }
            this.trashingItem.ValidoPerCoppieMute = false;
        });
    }

    public getSexIcon(genere) {
        if (genere.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public deleteDogConfirmed(owner, user) {
        this.subscription.add(
            this.apiService.get("Dogs/DeleteDog", { dogId: this.trashingItem.Id, ownerId: owner.Id, impersonamento: user.impersonate }).subscribe(ret => {
                if (!ret.ex) {
                    this.mydogs.MyOwnedDogs = this.mydogs.MyOwnedDogs.filter(d => d.Id != this.trashingItem.Id);
                    this.toastr.success(this.translocoService.translate('fe.label.dog.deletesuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
                }
                this.loadDogs();
                this.modalDeleteDogRef.hide();
            }));
    }

    public unconnectDogConfirmed(owner) {
        this.subscription.add(
            this.apiService.get("Dogs/UnconnectDog", { dogId: this.trashingItem.Id }).subscribe(ret => {
                if (ret.ex) {
                    this.loadDogs();
                    this.toastr.warning(ret.ex, this.translocoService.translate('fe.generic.warning'), { disableTimeOut: true, closeButton: true });
                }
                else {
                    this.mydogs.MyConductedDogs = this.mydogs.MyConductedDogs.filter(d => d.Id != this.trashingItem.Id);
                    this.toastr.success(this.translocoService.translate('fe.label.dog.unconnectsuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
                }
                this.loadDogs();
                this.modalUnconnectDogRef.hide();
            }));
    }

    public searching = false;
    public canSave = false;
    public canConnect = false;
    public searchMessage = "";
    public dogFound: any = null;
    public levrieroFound: any = null;
    public lagottoFound: any = null;
    public cirnecoFound: any = null;
    public canAddForeignDog = false;
    public checkLegge2 = false;

    public searchText: string;
    public modalAddDogShow(t: TemplateRef<any>) {

        //Azzero lo stato del popup
        this.searchText = "";
        this.canSave = false;
        this.canConnect = false;
        this.searchMessage = "";
        this.dogFound = null;
        this.levrieroFound = null;
        this.lagottoFound = null;
        this.cirnecoFound = null;
        this.canAddForeignDog = false;
        this.checkLegge2 = false;

        this.modalAddDogRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-add-dog-ref' + (this.isTouchDevice ? '' : ' ngDraggable') });
    }

    public searchDog(searchText: string, owner: any, lang: string) {
        if (searchText) {
            this.searching = true;
            this.subscription.add(
                this.apiService.get("Dogs/SearchDog", { searchText: searchText, ownerId: owner.Id, pageLanguage: lang }).subscribe(ret => {
                    this.searching = false;
                    if (!ret.ex) {
                        this.dogFound = ret.dto.Dog;
                        this.levrieroFound = ret.dto.DatiLevriero;
                        this.lagottoFound = ret.dto.DatiLagotto;
                        this.cirnecoFound = ret.dto.DatiCirneco;
                        switch (ret.dto.SearchCode) {
                            case "FOUND_LOCKED":
                                this.canSave = false;
                                this.canConnect = false;
                                this.canAddForeignDog = false;
                                this.searchMessage = this.translocoService.translate('fe.label.FOUND_LOCKED');
                                break;
                            case "BAD_BREED":
                                this.canSave = false;
                                this.canConnect = false;
                                this.canAddForeignDog = false;
                                this.searchMessage = this.translocoService.translate('fe.label.BAD_BREED');
                                break;
                            case "SOLD_ABROAD":
                                this.canSave = false;
                                this.canAddForeignDog = true;
                                this.searchMessage = this.translocoService.translate('fe.label.SOLD_ABROAD');
                                break;
                            case "FOUND_DB_OWNER":
                                this.canSave = true;
                                this.canConnect = false;
                                this.canAddForeignDog = false;
                                this.searchMessage = this.translocoService.translate('fe.label.FOUND_DB_OWNER');
                                break;
                            case "FOUND_DB_NO_OWNER":
                                if (owner.IsConduttore || owner.IsIscrittoAlboAddestratori || owner.IsConduttoreCae || owner.IsConduttoreCoppieMute) {
                                    this.canSave = false;
                                    this.canConnect = true;
                                    this.canAddForeignDog = false;
                                    this.searchMessage = this.translocoService.translate('fe.label.FOUND_DB_NO_OWNER.conduttore');
                                }
                                else {
                                    this.canSave = false;
                                    this.canConnect = false;
                                    this.canAddForeignDog = false;
                                    this.searchMessage = this.translocoService.translate('fe.label.FOUND_DB_NO_OWNER.noconduttore');
                                }
                                break;
                            case "FOUND_WS_OWNER":
                                this.searchMessage = this.translocoService.translate('fe.label.FOUND_WS_OWNER');
                                this.canSave = true;
                                this.canConnect = false;
                                this.canAddForeignDog = false;
                                break;
                            case "FOUND_WS_NO_OWNER": //TODO: inserire il proprietario senza utente 
                                this.canSave = false;
                                this.canConnect = false;
                                this.canAddForeignDog = false;
                                if (owner.IsConduttore || owner.IsIscrittoAlboAddestratori) {
                                    this.searchMessage = this.translocoService.translate('fe.label.FOUND_WS_NO_OWNER.conduttore');
                                }
                                else {
                                    this.searchMessage = this.translocoService.translate('fe.label.FOUND_WS_NO_OWNER.noconduttore');
                                }
                                break;
                            default: //"NOT_FOUND"
                                this.canSave = false;
                                this.canConnect = false;
                                this.canAddForeignDog = true;
                                this.searchMessage = this.translocoService.translate('fe.label.NOT_FOUND');
                                break;
                        }
                    }
                })
            );
        }
    }

    public saving = false;

    public addForeignDog(t: TemplateRef<any>) {
        this.editdog = {
            AttachedPedigree: "",
            AttachedPedigreeName: "",
            AttachedRisultato: "",
            AttachedRisultatoName: "",
            AttachedRisultatoCir: "",
            AttachedRisultatoNameCir: "",

            AttachedAutorizzazioneRsr: "",
            AttachedAutorizzazioneRsrName: "",
            AttachedCertificatoRsr: "",
            AttachedCertificatoRsrName: ""
        };
        if (/^\d+$/.test(this.searchText)) {//solo numeri
            this.editdog.Chip = this.searchText;
        } else {
            this.editdog.Loi = this.searchText;
        }
        this.modalEditDogRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-my-dog-add-foreign' + (this.isTouchDevice ? '' : ' ngDraggable') });
    }

    private editdog: any;
    public editForeignDog(t: TemplateRef<any>, c: any) {
        this.modalEditDogRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg modal-my-dog-edit-foreign' + (this.isTouchDevice ? '' : ' ngDraggable') });
        this.originalDog = Object.assign({}, c);
        this.editdog = this.originalDog;
    }

    public openPopRequisiti(t: TemplateRef<any>, c: any) {
        this.modalRequisitiRef = this.modalService.show(t, { ignoreBackdropClick: true, class: 'modal-lg' + (this.isTouchDevice ? '' : ' ngDraggable') });
        this.editdog = c;
    }

    public saveNotIta(owner, lang, datiLevriero, datiLagotto, datiCirneco, limrsr) {
        this.originalDog = null;
        this.subscription.add(
            this.apiService.post("Dogs/Save", {
                Dog: this.editdog,
                Owner: owner,
                PageLanguage: lang,
                DatiLevriero: datiLevriero,
                DatiLagotto: datiLagotto,
                DatiCirneco: datiCirneco,
                AttachedPedigree: this.editdog.AttachedPedigree,
                AttachedPedigreeName: this.editdog.AttachedPedigreeName,
                AttachedRisultato: this.editdog.AttachedRisultato,
                AttachedRisultatoName: this.editdog.AttachedRisultatoName,
                AttachedRisultatoCir: this.editdog.AttachedRisultatoCir,
                AttachedRisultatoNameCir: this.editdog.AttachedRisultatoNameCir,

                AttachedAutorizzazioneRsr: this.editdog.AttachedAutorizzazioneRsr,
                AttachedAutorizzazioneRsrName: this.editdog.AttachedAutorizzazioneRsrName,
                AttachedCertificatoRsr: this.editdog.AttachedCertificatoRsr,
                AttachedCertificatoRsrName: this.editdog.AttachedCertificatoRsrName,

                AttachedLicenzaCacil: this.editdog.AttachedLicenzaCacil,
                AttachedLicenzaCacilName: this.editdog.AttachedLicenzaCacilName,

                AttachedLicenzaCacil2023: this.editdog.AttachedLicenzaCacil2023,
                AttachedLicenzaCacil2023Name: this.editdog.AttachedLicenzaCacil2023Name

            }).subscribe(ret => {
                this.saving = false;
                if (!ret.ex) {
                    this.mydogs.MyOwnedDogs = this.mydogs.MyOwnedDogs.filter(d => d.Id != ret.dto.Id);
                    this.mydogs.MyOwnedDogs.push(ret.dto);
                    this.mydogs.MyOwnedDogs.sort((a, b) => ((a.Nome > b.Nome) ? 1 : -1));
                    this.toastr.success(this.translocoService.translate('fe.label.dog.savesuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
                    if (this.modalEditDogRef) {
                        this.modalEditDogRef.hide();
                    }
                    if (this.modalAddDogRef) {
                        this.modalAddDogRef.hide();
                    }
                    //dopo il salvataggio mostro il popup che avvisa che gli rsr hanno limitazioni per le manifestazioni
                    if (this.editdog.TipoRegistrazione == 'R1' && !this.editdog.Id) {
                        this.modalLimitazioniRsrRef = this.modalService.show(limrsr, { ignoreBackdropClick: true, class: 'modal-lg modal-my-dog-edit-foreign' + (this.isTouchDevice ? '' : ' ngDraggable') });
                    }
                }
            })
        );
    }

    public save(owner, lang) {
        this.originalDog = null;
        this.saving = true;
        //I dati in this.dogFound sono gi� aggiornati da WS
        this.subscription.add(
            this.apiService.post("Dogs/Save", { Dog: this.dogFound, Owner: owner, PageLanguage: lang, DatiLevriero: this.levrieroFound, DatiLagotto: this.lagottoFound, DatiCirneco: this.cirnecoFound })
                .subscribe(ret => {
                    this.saving = false;
                    if (!ret.ex) {
                        this.mydogs.MyOwnedDogs = this.mydogs.MyOwnedDogs.filter(d => d.Id != ret.dto.Id);
                        this.mydogs.MyOwnedDogs.push(ret.dto);
                        this.mydogs.MyOwnedDogs.sort((a, b) => ((a.Nome > b.Nome) ? 1 : -1));
                        this.toastr.success(this.translocoService.translate('fe.label.dog.savesuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
                        this.modalAddDogRef.hide();
                    }
                })
        );
    }

    public connect(owner, lang) {
        this.saving = true;
        this.subscription.add(
            this.apiService.post("Dogs/Connect", { Dog: this.dogFound, Owner: owner, PageLanguage: lang }).subscribe(ret => {
                this.saving = false;
                if (!ret.ex) {
                    this.mydogs.MyConductedDogs = this.mydogs.MyConductedDogs.filter(d => d.Id != ret.dto.Id);
                    this.mydogs.MyConductedDogs.push(ret.dto);
                    this.mydogs.MyConductedDogs.sort((a, b) => ((a.Nome > b.Nome) ? 1 : -1));
                    this.toastr.success(this.translocoService.translate('fe.label.dog.linksuccessful'), this.translocoService.translate('fe.generic.operation.completed'));
                    this.modalAddDogRef.hide();
                }
            })
        );
    }

    public canConductDogs(owner) {
        return owner.IsIscrittoAlboAddestratori
            || owner.IsConduttoreCae
            || owner.IsConduttoreCoppieMute
            || owner.IsConduttore;
    }

    public modalMakeRaggShow(t: string) {
        this.contextService.openPop(t);
    }
}