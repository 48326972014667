import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'groupfilter',
    pure: false
})
export class GroupFilterPipe implements PipeTransform {
    transform(items: any[], group: number): any {
        if (!group) {
            return items;
        }
        
        var ret = items.filter(o => {
            if (o.Gruppo == group) {
                return true;
            }
            return false;
        });
        return ret;
    }
}