import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'uploadwithpreview',
    templateUrl: './uploadwithpreview.component.html',
    styleUrls: ['./uploadwithpreview.component.css']
})

export class ImageUploadWithPreviewComponent implements OnInit {

    @Output() attachedfile = new EventEmitter();
    @Input() public lang: string;

    fileData: File = null;
    previewUrl: any = null;
    fileUploadProgress: string = null;
    uploadedFilePath: string = null;
    constructor(private http: HttpClient) { }

    ngOnInit() {}

    fileProgress(fileInput: any) {
        this.fileData = fileInput.target.files[0];
        let reader = new FileReader();
        reader.onload = (e => {
            return (z => {
                var binaryData = z.target.result;
                let ret = {
                    file: window.btoa(binaryData),
                    name: this.fileData.name
                }
                this.attachedfile.emit(ret);
            });
        })(this.fileData);
        reader.readAsBinaryString(this.fileData);
        this.preview();
    }

    preview() {
        var mimeType = this.fileData.type;
        if (mimeType.match(/image\/*/) == null) {
            this.previewUrl = null;
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(this.fileData);
        reader.onload = (_event) => {
            this.previewUrl = reader.result;
        }
    }
}