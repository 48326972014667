import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, TemplateRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ContextService } from '../../context.service';

@Component({
    selector: 'singleragg',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './singleragg.component.html',
    styleUrls: ['./singleragg.component.css']
})
export class SingleRaggComponent implements OnInit, OnDestroy, OnChanges {

    @Input() public lang: string;
    @Input() public owner: any;
    @Input() public ragg: any;
    @Input() public enciLoiUrl: any;

    public showPanelSogg = false;
    private subscription: Subscription;
    constructor(
        private translocoService: TranslocoService,
        private modalService: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService,
        private contextService: ContextService,
        private base: BaseFunctions
    ) {
        this.subscription = new Subscription();
    }

    public async ngOnInit() {
    }

    public async ngOnChanges(changes: SimpleChanges) {
        this.showPanelSogg = false;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getSexIcon(genere) {
        if (genere.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public loadRaggDogs() {
        if (!this.showPanelSogg) {
            for (let i = 0; i < this.ragg.Soggetti.length; i++) {
                if (this.ragg.Soggetti.some(s => s.Cane == null)) {
                    this.apiService.get("Dogs/GetDog", { chip: this.ragg.Soggetti[i].Chip, pageLanguage: this.lang }).subscribe(ret => {
                        if (!ret.ex) {
                            this.ragg.Soggetti[i].Cane = ret.dto;
                            this.showPanelSogg = true;
                        }
                    });
                } else {
                    this.showPanelSogg = true;
                }
            }
        } else {
            this.showPanelSogg = !this.showPanelSogg;
        }

    }
}