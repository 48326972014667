import { Component, OnInit, ViewEncapsulation, OnDestroy, TemplateRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ContextService } from '../context.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TranslocoService, AvailableLangs } from '@ngneat/transloco';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'az-confemailerror',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './confemailerror.component.html'
})
export class ConfEmailErrorComponent implements OnInit {

    public user$: Observable<any>;

    constructor(private translocoService: TranslocoService,
        private contextService: ContextService,
        private oidcSecurityService: OidcSecurityService,
        private modalService: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService) {
    }

    public async ngOnInit() {
        this.user$ = this.contextService.currentUser$;
    }
}