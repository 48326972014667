import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'az-error',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {

  constructor(private router: Router) {
    this.router = router;
  }

  searchResult(): void {
    this.router.navigate(['pages/search']);
  }

  public async ngOnInit() {
  }
}