import { Component, OnInit } from '@angular/core';

declare function normalizeSlideHeights(): any;
// normalizeSlideHeights : implementata nel file \GestioneProveFrontEnd\ClientApp\src\scripts\ENCI_globals.js

@Component({
    selector: 'ENCI_Warnings',
    templateUrl: './ENCI_Warnings.component.html'
})
export class ENCI_WarningsComponent implements OnInit {
    ngOnInit(): void {
        normalizeSlideHeights();
    }
}
