import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
    selector: 'app-count-down',
    templateUrl: './count-down.component.html',
    styleUrls: ['./count-down.component.css']
})
export class CountDownComponent implements OnInit, OnDestroy {

    @Input() public startSeconds: number = 0;
    @Input() public postiPrenotati: number = 0;
    @Input() public titleLabelLeft: string = "Cani da iscrivere";
    @Input() public daysLabel: string = "giorni";
    @Input() public hoursLabel: string = "ore";
    @Input() public minutesLabel: string = "min";
    @Input() public secondsLabel: string = "sec";
    @Input() public titleLabelRight: string = "Tempo rimanente";

    @Output() endcountdown = new EventEmitter();

    private subscription: Subscription;

    public dateScad: Date;

    milliSecondsInASecond = 1000;
    hoursInADay = 24;
    minutesInAnHour = 60;
    SecondsInAMinute = 60;

    public timeDifference;
    public secondsToDday;
    public minutesToDday;
    public hoursToDday;
    public daysToDday;

    public hide: boolean = false;

    constructor() { }

    ngOnInit() {
        this.dateScad = new Date(new Date().getTime() + this.startSeconds * 1000);
        this.subscription = interval(1000)
            .subscribe(x => {
                this.getTimeDifference();
                this.startSeconds--;
                if (this.startSeconds <= 0 || !this.postiPrenotati) {
                    this.hide = true;
                    this.endcountdown.emit(true);
                }
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private getTimeDifference() {
        this.timeDifference = this.dateScad.getTime() - new Date().getTime();
        this.allocateTimeUnits(this.timeDifference);
    }

    private allocateTimeUnits(timeDifference) {
        this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
        this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
        this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
        this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
    }

}
