import { Component, ViewEncapsulation, Input, TemplateRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, BaseFunctions } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ModalService } from '../../app.modalservice';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'eventdetailsmodals',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './eventdetailsmodals.component.html',
    styleUrls: ['./eventdetailsmodals.component.css']
})
export class EventDetailsModalsComponent implements AfterViewInit, OnDestroy {


    @Input() public lang: any;
    @Input() public enciLoiUrl: any;

    public modalInfoRef: BsModalRef | null;
    public modalResultRef: BsModalRef | null;
    public modalPlaybillRef: BsModalRef | null;
    public modalVetogeneRef: BsModalRef | null;
    public modalEspertiGiudiciRef: BsModalRef | null;

    subscription: Subscription = new Subscription();

    @ViewChild('modalInfo', { static: false }) modalInfo: TemplateRef<any>;
    @ViewChild('modalResult', { static: false }) modalResult: TemplateRef<any>;
    @ViewChild('modalVetogene', { static: false }) modalVetogene: TemplateRef<any>;

    private eventdetails$: Observable<any>;
    private eventresults$: Observable<any>;
    private allegati$: Observable<any>;
    private categorie$: Observable<any>;
    private playbill$: Observable<any>;
    private playbillPdf$: Observable<any>;
    private limitazioni$: Observable<any>;
    
    public evento: any;

    public nuovoRegolamentoLevrieri: boolean = false;

    constructor(
        private translocoService: TranslocoService,
        private bsModalService: BsModalService,
        private apiService: ApiService,
        private toastr: ToastrService,
        private base: BaseFunctions,
        private modalService: ModalService
    ) { }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngAfterViewInit() {
        this.apiService.get("/Utils/GetGpVariabiliGlobaliByChiave", { chiave: "LevrieriDataNuovoRegolamento2022" }).subscribe(reglev => {
            this.categorie$ = this.apiService.get("Utils/GetTypeOfAttachment", { pageLanguage: this.lang });
            this.subscription.add(this.modalService.modalInfo$.subscribe((result) => {
                if (result.open) {
                    this.evento = result.evento;
                    this.nuovoRegolamentoLevrieri = (this.base.getDate(this.evento.DataDal) >= this.base.getDateFormStringDDMMYYYY(reglev.dto, "/"));
                    this.eventdetails$ = this.apiService.get("Events/GetSubTrials", { trialId: result.evento.IdProvaEvento, pageLanguage: this.lang });
                    //this.eventresults$ = this.apiService.get("Results/GetSubTrialsResults", { trialId: result.evento.IdProvaEvento, pageLanguage: this.lang });
                    this.allegati$ = this.apiService.get("Events/GetAllegatiEvento", { idGpEvento: result.evento.Id });
                    this.limitazioni$ = this.apiService.get("Events/GetLimitazioniEvento", { idGpEvento: result.evento.Id, lang: this.lang });

                    this.modalInfoRef = this.bsModalService.show(this.modalInfo, { ignoreBackdropClick: true, animated: false, class: 'modal-lg modal-dog-list-item-show-info' });
                    if (result.evento.PlaybillId) {
                        this.playbill$ = this.apiService.get("Utils/GetAttachImage", { attachId: result.evento.PlaybillId });
                    } else {
                        this.playbill$ = null;
                    }
                    if (result.evento.PlaybillPdfId) {
                        this.playbillPdf$ = this.apiService.get("Utils/GetAttach", { attachId: result.evento.PlaybillPdfId });
                    } else {
                        this.playbillPdf$ = null;
                    }
                } else {
                    this.modalInfoRef.hide();
                }
            }));

            this.subscription.add(this.modalService.modalResult$.subscribe((result) => {
                if (result.open) {
                    this.evento = result.evento;
                    this.nuovoRegolamentoLevrieri = (this.base.getDate(this.evento.DataDal) >= this.base.getDateFormStringDDMMYYYY(reglev.dto, "/"));
                    this.eventdetails$ = this.apiService.get("Events/GetSubTrials", { trialId: result.evento.IdProvaEvento, pageLanguage: this.lang });
                    this.eventresults$ = this.apiService.get("Results/GetSubTrialsResults", { trialId: result.evento.IdProvaEvento, pageLanguage: this.lang });
                    this.allegati$ = this.apiService.get("Events/GetAllegatiEvento", { idGpEvento: result.evento.Id });

                    this.modalResultRef = this.bsModalService.show(this.modalResult, { ignoreBackdropClick: true, animated: false, class: 'modal-lg modal-dog-list-item-show-info' });
                    if (result.evento.PlaybillId) {
                        this.playbill$ = this.apiService.get("Utils/GetAttachImage", { attachId: result.evento.PlaybillId });
                    }
                    if (result.evento.PlaybillPdfId) {
                        this.playbillPdf$ = this.apiService.get("Utils/GetAttach", { attachId: result.evento.PlaybillPdfId });
                    }
                } else {
                    this.modalResultRef.hide();
                }
            }));

            this.subscription.add(this.modalService.modalVetogene$.subscribe((result) => {
                if (result.open) {
                    this.modalVetogeneRef = this.bsModalService.show(this.modalVetogene, { ignoreBackdropClick: true, animated: false, class: 'modal-lg' });
                } else {
                    this.modalVetogeneRef.hide();
                }
            }));

        });
    }

    public closeModalInfo() {
        this.modalService.closeModalInfo();
    }

    public closeModalResult() {
        this.modalService.closeModalResult();
    }

    public showModalPlaybill(t) {
        if (this.evento.PlaybillId) {
            this.modalPlaybillRef = this.bsModalService.show(t, { ignoreBackdropClick: true, animated: false, class: 'modal-lg modal-dog-list-item-show-info' });
        }
    }

    public noIscrittiSP(sp) {
        return ((sp.CodSubKey == 'COPPIE' || sp.CodSubKey == 'MUTE' || sp.CodSubKey == 'BREVETTO DI MUTA') ? sp.NumCoppieMuteIscritte : sp.NumSoggettiIscritti) + this.evento.PostiPrenotati;
    }

    public noIscrittiEV(ev) {
        return ev.NumSoggettiIscrittiGlobale + ev.PostiPrenotati;
    }

    public closeModalVetogene() {
        this.modalService.closeModalVetogene();
    }

    public getIntegerPart(number) {
        return Math.floor(number);
    }

    public getSexIcon(sesso) {
        if (sesso && sesso.toUpperCase() === "F")
            return "venus";
        return "mars";
    };

    public download(attach) {

        var byteCharacters = atob(attach.ByteArray);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var blob = new Blob([new Uint8Array(byteNumbers)], { type: attach.MimeType });
        if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
            var a = document.createElement("a");
            a.download = attach.FileName;
            a.href = window["webkitURL"].createObjectURL(blob);
            a;
            a.click();
            setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
        } else {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            //a.style = "display: none";
            a.href = url;
            a.download = attach.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }

    public showNoteSuddivisione(noteSuddivisione) {
        // Soliti accrocchi per dati del cacchio (solo alcune note si devono visualizzare)
        return noteSuddivisione == 'CONDUTTORI DILETTANTI'
            || (noteSuddivisione && (noteSuddivisione.indexOf('RISERVATA') > -1))
            || (noteSuddivisione && (noteSuddivisione.indexOf('BREVETTO DI MONITORAGGIO') > -1));
    }

    public getCategory(categorie: any[], catId) {
        return categorie.find(t => t.Id == catId).Description;
    }

    public openAttachment(all) {
        document.body.classList.add('busy-cursor');
        this.apiService.get("/Utils/GetByteArrayById", { allId: all.Id }).subscribe(ret => {
            this.download(ret.dto);
            document.body.classList.remove('busy-cursor');
        });
    }

    public currentEspertiGiudici = [];

    public getEspertiGiudici(t, cat) {
        this.currentEspertiGiudici = [];
        this.apiService.get("Results/GetEspertiGiudiciByIdCatalogo", { idCatalogo: cat.Id }).subscribe(giudici => {
            this.currentEspertiGiudici = giudici.dto.map(g => g.Nome + " (" + g.CodNazione + ")");
            this.modalEspertiGiudiciRef = this.bsModalService.show(t, { ignoreBackdropClick: true });
        });
    }

    public getEspertiGiudiciCatDir(t, cat) {
        this.currentEspertiGiudici = [];
        this.apiService.get("Results/GetEspertiGiudiciByIdCatalogoDiretto", { idCatalogoDiretto: cat.Id }).subscribe(giudici => {
            this.currentEspertiGiudici = giudici.dto.map(g => g.Nome + " (" + g.CodNazione + ")");
            this.modalEspertiGiudiciRef = this.bsModalService.show(t, { ignoreBackdropClick: true });
        });
    }


}