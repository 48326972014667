import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'razzafilter',
    pure: false
})
export class RazzaFilterPipe implements PipeTransform {
    transform(items: any[], filter: number, mode: string): any {
        if (!items || !filter) {
            return items;
        }

        let ret = items;
        switch (mode) {
            case "S":
                ret = ret.filter(sp => sp.CodSubKey != 'COPPIE' && sp.CodSubKey != 'MUTE' && sp.CodSubKey != 'BREVETTO DI MUTA');
                break;
            case "C": 
                ret = ret.filter(sp => sp.CodSubKey == 'COPPIE');
                break;
            case "M":
                ret = ret.filter(sp => sp.CodSubKey == 'MUTE' || sp.CodSubKey == 'BREVETTO DI MUTA');
            default:
                break;
        }

        ret = ret.filter(o => {
            if (o.Razze.some(r => r.Id == filter)) {
                return true;
            }
            return false;
        });
        return ret;
    }
}